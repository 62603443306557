import { removeToastAlert } from "../Toast/toastAction";
import { CLOSE_MODAL, SHOW_MODAL } from "./modalTypes";

export const showModal = (data, modalType, modalProps) => async (dispatch) => {
	dispatch(removeToastAlert());
	dispatch({
		type: SHOW_MODAL,
		payload: { data, modalType, modalProps },
	});
};

export const closeModal = () => async (dispatch) => {
	dispatch({
		type: CLOSE_MODAL,
	});
};
