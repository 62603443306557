import{
    LOAN_CHARGE_REQUEST,
    CREATE_LOAN_CHARGE,
    LOAN_CHARGE_ERROR,
    UPDATE_LOAN_CHARGE,
    GET_ALL_LOAN_CHARGE
} from "./loanChargeTypes";

const initialState = {
	getLoanCharge: [],
	loanCharges: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAN_CHARGE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_LOAN_CHARGE:
		case UPDATE_LOAN_CHARGE:
			return {
				...state,
				loanCharges: payload,
				loading: false,
			};
		case GET_ALL_LOAN_CHARGE:
			return {
				...state,
				getLoanCharge: payload,
				loading: false,
			};
		case LOAN_CHARGE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}