import {
	COLLATERAL_TYPES_ERROR,
	COLLATERAL_TYPES_REQUEST, CREATE_COLLATERAL_TYPE,
	GET_COLLATERAL_TYPES
} from "./collateralTypesTypes";

const initialState = {
	collateralType: {},
	allCollateralTypes: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case COLLATERAL_TYPES_REQUEST:
			return {
				...state,
				loading: false,
			};
		case CREATE_COLLATERAL_TYPE:
			return {
				...state,
				collateralType: payload,
				loading: false,
			};
		case GET_COLLATERAL_TYPES:
			return {
				...state,
				allCollateralTypes: payload,
				loading: false,
			};
		case COLLATERAL_TYPES_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
