import {
  CREATE_LOAN_GUARANTOR,
  GET_ALL_GUARANTORS_BY_LOAN_CODE,
  GET_ALL_LOAN_GUARANTOR,
  GET_GUARANTOR_DETAILS,
  LOAN_GUARANTOR_ERROR,
  LOAN_GUARANTOR_REQUEST,
  UPDATE_LOAN_GUARANTOR,
  SUBSTITUTE_GUARANTOR,
  SEARCH_GUARANTOR,
} from "./loanGuarantorTypes";

const initialState = {
  getLoanGuarantors: [],
  loanGuarantors: [],
  guarantorsByLoanCode: [],
  guarantorDetails: null,
  selectedGuarantor: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAN_GUARANTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LOAN_GUARANTOR:
    case UPDATE_LOAN_GUARANTOR:
    case SUBSTITUTE_GUARANTOR:
      return {
        ...state,
        loanGuarantors: payload,
        loading: false,
      };
    case GET_ALL_LOAN_GUARANTOR:
      return {
        ...state,
        getLoanGuarantors: payload,
        loading: false,
      };
    case GET_ALL_GUARANTORS_BY_LOAN_CODE:
      return {
        ...state,
        guarantorsByLoanCode: payload,
        loading: false,
      };
    case GET_GUARANTOR_DETAILS:
      return {
        ...state,
        guarantorDetails: payload,
        loading: false,
      };
    case SEARCH_GUARANTOR:
      return {
        ...state,
        selectedGuarantor: payload,
        loading: false
      }
    case LOAN_GUARANTOR_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
