import { GET_ALL_ACC_STATUS_DESCRIPTION, CREATE_ACCOUNT_STATUS_DESCRIPTION, ACC_STATUS_DESC_ERROR, UPDATE_ACCOUNT_STATUS_DESCRIPTION } from "./types";

const initialState = {
  status: "",
  allStatus: [],
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ACCOUNT_STATUS_DESCRIPTION:
    case UPDATE_ACCOUNT_STATUS_DESCRIPTION:
      return {
        ...state,
        status: payload,
        loading: false
      }
    case GET_ALL_ACC_STATUS_DESCRIPTION:
      return {
        ...state,
        allStatus: payload,
        loading: false
      }
    case ACC_STATUS_DESC_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}