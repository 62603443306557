/* eslint-disable import/no-anonymous-default-export */

import {
	CREATE_EMPLOYER_DETAILS,
  EMPLOYER_DETAILS_ERROR,
  EMPLOYER_DETAILS_REQUEST,
  GET_ALL_EMPLOYER_DETAILS,
  GET_EMPLOYER_DETAILS_BY_CODE,
} from "./employerDetailsTypes";

const initialState = {
  employerDetails: [],
  allEmployersRecord: [],
  singleEmployerRecord: {},
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMPLOYER_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CREATE_EMPLOYER_DETAILS:
      return {
        ...state,
        employerDetails: payload,
        loading: false,
      };
    case GET_ALL_EMPLOYER_DETAILS:
      return {
        ...state,
        allEmployersRecord: payload,
        loading: false,
      };
    case GET_EMPLOYER_DETAILS_BY_CODE:
      return {
        ...state,
        singleEmployerRecord: payload,
        loading: false,
      };
    case EMPLOYER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
