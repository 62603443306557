import {
	LOAN_REGISTER_REQUEST,
	CREATE_LOAN_REGISTER,
	GET_LOAN_REGISTER,
	LOAN_REGISTER_ERROR,
	UPDATE_LOAN_REGISTER,
	UPDATE_LOAN_REGISTER_STATUS,
	GET_LOAN_REGISTER_BY_LOANCODE,
	GET_REGISTER_BY_ACCOUNTNUMBER,
	GET_LOAN_BY_LOAN_CODE,
	LOAN_REGISTERED_APPROVAL,
	GET_LOAN_STAGE,
	DISBURSE_LOAN,
	APPRAISE_LOAN,
	APPROVE_LOAN,
	GET_LOAN_REGISTERED_APPRAISAL,
	GET_INCOME_CALCULATION_APPRAISAL,
	GET_LOAN_OFFICER_CALCULATION,
	GET_LOAN_APPROVAL_BY_LOAN_CODE,
	GET_DISBURSEMENT_BY_LOAN_CODE,
	LOAN_CAPITALIZATION,
	CREATE_UPDATE_LOAN_PURPOSE,
	GET_ALL_LOAN_PURPOSE,
	CLEAR_LOAN_REGISTERS
} from "./loanRegisterType";

const initialState = {
	loanRegister: null,
	allLoanRegister: [],
	loanRegisterByLoanCode: [],
	allLoanRegisterByAccNo: [],
	loanByLoanCode: [],
	loanApproval: [],
	isLoanApproved: false,
	loanStages: [],
	loanDisbursed: [],
	isLoanDisbursed: false,
	loanAppraised: null,
	loanRegisteredAppraisal: null,
	incomeCalculationAppraisal: null,
	loanOfficerCalculations: null,
	loanApproved: null,
	loanApprovalByLoanCode: null,
	disbursementByLoanCode: null,
	loanCapitalization: {},
	allLoanPurpose: [],
	loanPurpose: {},
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAN_REGISTER_REQUEST:
			return {
				...state,
				loading: false,
				isLoanApproved: false,
				isLoanDisbursed: false,
			};
		case CREATE_LOAN_REGISTER:
		case UPDATE_LOAN_REGISTER:
		case UPDATE_LOAN_REGISTER_STATUS:
			return {
				...state,
				loanRegister: payload,
				loading: false,
				isLoanApproved: true,
			};
		case GET_LOAN_REGISTER:
			return {
				...state,
				allLoanRegister: payload,
				loading: false,
			};
		case GET_LOAN_REGISTER_BY_LOANCODE:
			return {
				...state,
				loanRegisterByLoanCode: payload,
				loading: false,
			};
		case GET_REGISTER_BY_ACCOUNTNUMBER:
			return {
				...state,
				allLoanRegisterByAccNo: payload,
				loading: false,
			};
		case GET_LOAN_BY_LOAN_CODE:
			return {
				...state,
				loanByLoanCode: payload,
				loading: false,
			};
		case LOAN_REGISTERED_APPROVAL:
			return {
				...state,
				loanApproval: payload,
				isLoanApproved: true,
				loading: false,
			};
		case GET_LOAN_STAGE:
			return {
				...state,
				loanStages: payload,
				loading: false,
			};
		case DISBURSE_LOAN:
			return {
				...state,
				loanDisbursed: payload,
				isLoanDisbursed: true,
				loading: false,
			};
		case APPRAISE_LOAN:
			return {
				...state,
				loanAppraised: payload,
				loading: false,
			};
		case GET_LOAN_REGISTERED_APPRAISAL:
			return {
				...state,
				loanRegisteredAppraisal: payload,
				loading: false,
			};
		case GET_INCOME_CALCULATION_APPRAISAL:
			return {
				...state,
				incomeCalculationAppraisal: payload,
				loading: false,
			};
		case GET_LOAN_OFFICER_CALCULATION:
			return {
				...state,
				loanOfficerCalculations: payload,
				loading: false,
			};
		case APPROVE_LOAN:
			return {
				...state,
				loanApproved: payload,
				loading: false,
			};
		case GET_LOAN_APPROVAL_BY_LOAN_CODE:
			return {
				...state,
				loanApprovalByLoanCode: payload,
				loading: false,
			};
		case GET_DISBURSEMENT_BY_LOAN_CODE:
			return {
				...state,
				disbursementByLoanCode: payload,
				loading: false,
			};
		case LOAN_CAPITALIZATION:
			return {
				...state,
				loanCapitalization: payload,
				loading: false,
			};
		case CREATE_UPDATE_LOAN_PURPOSE:
			return { ...state, loading: false, loanPurpose: payload };
		case GET_ALL_LOAN_PURPOSE:
			return { ...state, allLoanPurpose: payload, loading: false };
		case LOAN_REGISTER_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case CLEAR_LOAN_REGISTERS:
			return initialState
		default:
			return state;
	}
}
