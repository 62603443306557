import {
  CREATE_SAVING_PRODUCT,
  SAVING_PRODUCT_ERROR,
  SAVING_PRODUCT_REQUEST,
  GET_ALL_SAVING_PRODUCTS,
  GET_FOSA_PAYMENT_CATEGORY
} from './savingProductTypes'

const initialState = {
  savingProducts: [],
  fosaPaymentCategories: [],
  loading: false,
  error: null
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SAVING_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_SAVING_PRODUCT:
      return {
        ...state,
        loading: false,
      }
    case GET_ALL_SAVING_PRODUCTS:
      return {
        ...state,
        loading: false,
        savingProducts: payload
      }
    case SAVING_PRODUCT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case GET_FOSA_PAYMENT_CATEGORY:
      return {
        ...state,
        fosaPaymentCategories: payload,
        loading: false
      }
    default:
      return state;
  }
}