import { CLOSE_MODAL, SHOW_MODAL } from "./modalTypes";

const initialState = {
	modalType: null,
	modalProps: "",
	modalShow: false,
	data: [],
	id: 0,
};


export default function (state = initialState, action) {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				modalShow: true,
				data: action.payload.data,
				modalType: action.payload.modalType,
				modalProps: action.payload.modalProps,
			};
		case CLOSE_MODAL:
			return initialState;
		default:
			return state;
	}
}
