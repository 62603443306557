export const DEPOSIT_PRODUCT_REQUEST = "DEPOSIT_PRODUCT_REQUEST";
export const CREATE_DEPOSIT_PRODUCT = "CREATE_DEPOSIT_PRODUCT";
export const DEPOSIT_PRODUCT_ERROR = "DEPOSIT_PRODUCT_ERROR";
export const UPDATE_DEPOSIT_PRODUCT = "UPDATE_DEPOSIT_PRODUCT";
export const GET_ALL_DEPOSIT_PRODUCTS = "GET_ALL_DEPOSIT_PRODUCTS";
export const GET_DEPOSIT_PRODUCT_BY_ID = "GET_DEPOSIT_PRODUCT_BY_ID";
export const GET_DEPOSIT_PRODUCT_FOR_LOAN = "GET_DEPOSIT_PRODUCT_FOR_LOAN";
export const GET_CUSTOMER_BALANCE_LIST = "GET_CUSTOMER_BALANCE_LIST";
export const GET_ALL_DEPOSIT_CHARGES = "GET_ALL_DEPOSIT_CHARGES";

export const CREATE_WITHDRAWAL = "CREATE_WITHDRAWAL";
export const CREATE_DEPOSIT_CHARGES = "CREATE_DEPOSIT_CHARGES";
export const CLEAR_DEPOSIT_PRODUCTS = "CLEAR_DEPOSIT_PRODUCTS";
