/* eslint-disable import/no-anonymous-default-export */
import {
	ACCOUNT_PURPOSE_ERROR,
	ACCOUNT_PURPOSE_REQUEST,
	CREATE_ACCOUNT_PURPOSE,
	GET_ALL_ACCOUNT_PURPOSE,
	UPDATE_ACCOUNT_PURPOSE,
} from "./accountPurposeTypes";

const initialState = {
	accountCategory: "",
	allAccountPurpose: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case ACCOUNT_PURPOSE_REQUEST:
			return { ...state, loading: true };
		case CREATE_ACCOUNT_PURPOSE:
		case UPDATE_ACCOUNT_PURPOSE:
			return {
				...state,
				accountCategory: payload,
				loading: false,
			};
		case GET_ALL_ACCOUNT_PURPOSE:
			return {
				...state,
				allAccountPurpose: payload,
				loading: false,
			};
		case ACCOUNT_PURPOSE_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}
