
import {
	LEVELIII_COA_CREATION_REQUEST,
	LEVELIII_COA_CREATION_SUCCESS,
	LEVELIII_COA_CREATION_FAIL,
	GET_ALL_LEVELIII_COA_SUCCESS,
	GET_ALL_LEVELIII_COA_FAIL,
	LEVELIII_COA_UPDATE,
} from "./levelIIICOATypes";

const initialState = {
	coalevelIII: "",
	allLevelIIICOA: [],
    allLevelIICOA: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LEVELIII_COA_CREATION_REQUEST:
			return { ...state, loading: true };
		case LEVELIII_COA_CREATION_SUCCESS:
		case LEVELIII_COA_UPDATE:
			return {
				...state,
				coalevelIII: payload,
				loading: false,
			};
		case LEVELIII_COA_CREATION_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_ALL_LEVELIII_COA_SUCCESS:
			return {
				...state,
				allLevelIIICOA: payload,
				loading: false,
			};
		case GET_ALL_LEVELIII_COA_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}