export const REPORT_REQUEST = "REPORT_REQUEST";
export const REPORT_ERROR = "REPORT_ERROR";

export const GET_REFERAL_ACCOUNT = "GET_REFERAL_ACCOUNT";
export const GET_STATUS_BY_DATE = "GET_STATUS_BY_DATE";
export const GET_STATUS = "GET_STATUS";
export const GET_MBANKING_STATUS = "GET_MBANKING_STATUS";
export const GET_DISBURSED_DIVIDENDS_BY_YEAR = "GET_DISBURSED_DIVIDENDS_BY_YEAR";
export const GET_DISBURSED_DIVIDENDS_BY_PRODUCT_CODE = "GET_DISBURSED_DIVIDENDS_BY_PRODUCT_CODE";
export const GET_DISBURSED_DIVIDENDS_BY_BRANCH = "GET_DISBURSED_DIVIDENDS_BY_BRANCH";
export const GET_GUARANTOR_LOANEE = "GET_GUARANTOR_LOANEE";
export const GET_LOAN_GUARANTOR = "GET_LOAN_GUARANTOR";
export const GET_LOAN_DISBURSEMENT_BY_FILTERS = "GET_LOAN_DISBURSEMENT_BY_FILTERS";
export const GET_LOAN_BY_STAGES = "GET_LOAN_BY_STAGES";

export const GET_LOAN_STATEMENT = "GET_LOAN_STATEMENT";

export const GET_DEPOSIT_BALANCE_LISTING = "GET_DEPOSIT_BALANCE_LISTING";
export const GET_DEPOSIT_BALANCE_LISTING_BETWEEN_DATE = "GET_DEPOSIT_BALANCE_LISTING_BETWEEN_DATE";
export const GET_DEPOSIT_STATEMENT = "GET_DEPOSIT_STATEMENT";
export const GET_LEDGER_SINGLE_ACCOUNT = "GET_LEDGER_SINGLE_ACCOUNT";
export const GET_GENERAL_TRANSACTION_AUDIT = "GET_GENERAL_TRANSACTION_AUDIT";

export const GET_DEPOSIT_BALANCE_BY_DATE = "GET_DEPOSIT_BALANCE_BY_DATE";
export const GET_DAILY_WITHDRAWAL_REPORT = "GET_DAILY_WITHDRAWAL_REPORT";
export const GET_ALL_LOAN_BALANCE_LIST = "GET_ALL_LOAN_BALANCE_LIST";
export const GET_LOAN_TYPE_BALANCE = "GET_LOAN_TYPE_BALANCE";
export const GET_LOAN_BAL_WITH_DEPOSITS = "GET_LOAN_BAL_WITH_DEPOSITS";
export const GET_SAVINGS_REPORT = "GET_SAVINGS_REPORT";
export const GET_FOSA_SAVING_REPORT = "GET_FOSA_SAVING_REPORT";
export const CLEAR_REPORT_STATE = "CLEAR_REPORT_STATE";