import {
	CREATE_DOCUMENT_INFO,
	DOCUMENT_INFO_ERROR,
	DOCUMENT_INFO_REQUEST,
	GET_ALL__DOCUMENTS_INFO,
	UPDATE_DOCUMENT_INFO
} from "./documentInfoTypes";

const initialState = {
	allDocumentsInfo: [],
	documentInfo: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DOCUMENT_INFO_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_DOCUMENT_INFO:
		case UPDATE_DOCUMENT_INFO:
			return {
				...state,
				documentInfo: [payload, ...state.documentInfo],
				loading: false,
			};
		case GET_ALL__DOCUMENTS_INFO:
			return {
				...state,
				allDocumentsInfo: payload,
				loading: false,
			};
		case DOCUMENT_INFO_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
