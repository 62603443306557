import {
	CASH_RECEIPT_ERROR,
	CASH_RECEIPT_REQUEST,
	CREATE_CASH_RECEIPT,
	GET_CASH_RECEIPT_DETAILS,
	CREATE_CASH_RECEIVED_LIST,
	ENABLE_BUTTON,
	GET_CASH_TRANSFER_DETAILS,
	GET_ALL_TELLERS,
	GET_COA_FOR_TREASURY,
	GET_BANK_BALANCE,
	GET_TREASURY_BALANCE,
	GET_TELLER_BALANCE
} from "./cashReceivedTypes";

const initialState = {
	cashReceipt: [],
	cashReceivedList: {},
	bankBalance: 0,
	treasuryBalance: 0,
	tellerBalance: 0,
	cashReceiptDetails: {},
	isCashReceived: false,
	cashTransferDetails: {},
	allTellers: [],
	coaForTeasury: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload, bankBalance, treasuryBalance, tellerBalance } = action;

	switch (type) {
		case CASH_RECEIPT_REQUEST:
			return {
				...state,
				loading: true,
				isCashReceived: false,
			};
		case GET_BANK_BALANCE:
			return {
				...state,
				bankBalance
			}
		case GET_TREASURY_BALANCE:
			return {
				...state,
				treasuryBalance
			}
		case GET_TELLER_BALANCE:
			return {
				...state,
				tellerBalance
			}
		case ENABLE_BUTTON:
			return {
				...state,
				loading: false,
				isCashReceived: false,
			};
		case CREATE_CASH_RECEIPT:
			return {
				...state,
				cashReceipt: payload,
				isCashReceived: true,
				loading: false,
			};
		case GET_CASH_RECEIPT_DETAILS:
			return {
				...state,
				cashReceiptDetails: payload,
				loading: false,
			};
		case CREATE_CASH_RECEIVED_LIST:
			return {
				...state,
				isCashReceived: true,
				cashReceivedList: payload,
				loading: false,
			};
		case GET_CASH_TRANSFER_DETAILS:
			return {
				...state,
				cashTransferDetails: payload,
				loading: false,
			};
		case GET_ALL_TELLERS:
			return {
				...state,
				allTellers: payload,
				loading: false,
			};
		case GET_COA_FOR_TREASURY:
			return {
				...state,
				coaForTeasury: payload,
				loading: false,
			};
		case CASH_RECEIPT_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
