import {
	LEVELII_COA_CREATION_REQUEST,
	LEVELII_COA_CREATION_SUCCESS,
	LEVELII_COA_CREATION_FAIL,
	GET_ALL_LEVELII_COA_SUCCESS,
	GET_ALL_LEVELII_COA_FAIL,
	LEVELII_COA_UPDATE,
} from "./levelIICOATypes"

const initialState = {
	coalevelII: "",
	allLevelIICOA: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LEVELII_COA_CREATION_REQUEST:
			return { ...state, loading: true };
		case LEVELII_COA_CREATION_SUCCESS:
		case LEVELII_COA_UPDATE:
			return {
				...state,
				coalevelII: payload,
				loading: false,
			};
		case LEVELII_COA_CREATION_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_ALL_LEVELII_COA_SUCCESS:
			return {
				...state,
				allLevelIICOA: payload,
				loading: false,
			};
		case GET_ALL_LEVELII_COA_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}