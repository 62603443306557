import {
	COMPUTE_DIVIDEND,
	DISBURSE_DIVIDENDS,
	DIVIDEND_COMPUTATION_ERROR,
	DIVIDEND_COMPUTATION_REQUEST,
	GET_DIVIDEND_COMPUTATION_BY_YEAR,
} from "./dividendComputationTypes";

const initialState = {
	dividendComputation: {},
	isDividendsComputed: false,
	dividendComputationByYear: [],
	dividendsDisbursement: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case DIVIDEND_COMPUTATION_REQUEST:
			return {
				...state,
				loading: true,
				isDividendsComputed: false,
			};
		case COMPUTE_DIVIDEND:
			return {
				...state,
				dividendComputation: payload,
				isDividendsComputed: true,
				loading: false,
			};
		case GET_DIVIDEND_COMPUTATION_BY_YEAR:
			return {
				...state,
				dividendComputationByYear: payload,
				loading: false,
			};
		case DISBURSE_DIVIDENDS:
			return {
				...state,
				dividendsDisbursement: payload,
				loading: false,
			};
		case DIVIDEND_COMPUTATION_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
