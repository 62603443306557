import {
	CREATE_SHARES_PRODUCT,
	GET_ALL_SHARES_PRODUCTS,
	GET_SHARES_PRODUCT_BY_ID,
	SHARES_PRODUCT_ERROR,
	SHARES_PRODUCT_REQUEST,
	UPDATE_SHARES_PRODUCT,
} from "./sharesProductTypes";

const initialState = {
	sharesProducts: [],
	sharesProduct: [],
	shareProductDetails: null,
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SHARES_PRODUCT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_SHARES_PRODUCT:
		case UPDATE_SHARES_PRODUCT:
			return {
				...state,
				sharesProduct: payload,
				loading: false,
			};
		case GET_ALL_SHARES_PRODUCTS:
			return {
				...state,
				sharesProducts: payload,
				loading: false,
			};
		case GET_SHARES_PRODUCT_BY_ID:
			return {
				...state,
				shareProductDetails: payload,
				loading: false,
			};
		case SHARES_PRODUCT_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
