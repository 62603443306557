import {
	LOAN_RECOVERY_ERROR,
	LOAN_RECOVERY_REQUEST, CREATE_LOAN_RECOVERY,
	GET_LOAN_RECOVERY,
	GET_LOAN_RECOVERY_BY_ID,
	GET_RECOVERY_BY_LOAN_ID
} from "./loanRecoveryTypes";

const initialState = {
	loanRecovery: {},
	allLoanRecovery: [],
	loanRecoverysById: [],
	recoverByLoanId: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAN_RECOVERY_REQUEST:
			return {
				...state,
				loading: false,
			};
		case CREATE_LOAN_RECOVERY:
			return {
				...state,
				loanRecovery: payload,
				loading: false,
			};
		case GET_LOAN_RECOVERY:
			return {
				...state,
				allLoanRecovery: payload,
				loading: false,
			};
			case GET_LOAN_RECOVERY_BY_ID:
			return {
				...state,
				loanRecoverysById: payload,
				loading: false,
			};
			case GET_RECOVERY_BY_LOAN_ID:
			return {
				...state,
				recoverByLoanId: payload,
				loading: false,
			};
		case LOAN_RECOVERY_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
