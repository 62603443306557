export const STANDING_ORDER_REQUEST = "STANDING_ORDER_REQUEST";

export const CREATE_STANDING_ORDER = "CREATE_STANDING_ORDER";
// export const UPDATE_STANDING_ORDER = "UPDATE_STANDING_ORDER";
export const GET_ACTIVE_STANDING_ORDER = "GET_ACTIVE_STANDING_ORDER";

export const GET_ALL_STANDING_ORDERS = "GET_ALL_STANDING_ORDERS";

export const STANDING_ORDER_ERROR = "STANDING_ORDER_ERROR";

export const STANDING_SERVICE = "CREATE_STANDING_SERVICE";

export const RESET_STANDING_ORDER = "RESET_STANDING_ORDER";
