import {
	LOAN_QUALIFICATION_ERROR,
	LOAN_QUALIFICATION_REQUEST, CREATE_LOAN_QUALIFICATION,
	GET_LOAN_QUALIFICATIONS,
	GET_LOAN_QUALIFICATION_BY_ID,
	GET_QUALIFICATION_BY_LOAN_ID
} from "./loanQualificationTypes";

const initialState = {
	loanQualification: {},
	allLoanQualifications: [],
	loanQualificationsById: [],
	qualificationByLoanId: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAN_QUALIFICATION_REQUEST:
			return {
				...state,
				loading: false,
			};
		case CREATE_LOAN_QUALIFICATION:
			return {
				...state,
				loanQualification: payload,
				loading: false,
			};
		case GET_LOAN_QUALIFICATIONS:
			return {
				...state,
				allLoanQualifications: payload,
				loading: false,
			};
			case GET_LOAN_QUALIFICATION_BY_ID:
			return {
				...state,
				loanQualificationsById: payload,
				loading: false,
			};
			case GET_QUALIFICATION_BY_LOAN_ID:
			return {
				...state,
				qualificationByLoanId: payload,
				loading: false,
			};
		case LOAN_QUALIFICATION_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
