export const COLLATERALS_REQUEST = "COLLATERALS_REQUEST";

export const CREATE_COLLATERAL = "CREATE_COLLATERAL";
export const GET_COLLATERALS = "GET_COLLATERALS";
export const UPDATE_COLLATERAL = "UPDATE_COLLATERAL";

export const UPDATE_VALIDATE_COLLATERAL = "UPDATE_VALIDATE_COLLATERAL";
export const UPDATE_RELEASE_COLLATERAL = "UPDATE_RELEASE_COLLATERAL";
export const UPDATE_ATTACH_COLLATERAL_TO_LOAN = "UPDATE_ATTACH_COLLATERAL_TO_LOAN";

export const SEARCH_COLLATERAL = "SEARCH_COLLATERAL";
export const GET_COLLATERALS_FOR_RELEASE = "GET_COLLATERALS_FOR_RELEASE";
export const GET_COLLATERALS_FOR_APPROVAL = "GET_COLLATERALS_FOR_APPROVAL";

export const GET_COLLATERALS_FOR_LOAN_ATTACHMENT = "GET_COLLATERALS_FOR_LOAN_ATTACHMENT";
export const GET_COLLATERAL_BY_LOAN_CODE = "GET_COLLATERAL_BY_LOAN_CODE";
export const ATTACH_COLLATERAL_TO_LOAN = "ATTACH_COLLATERAL_TO_LOAN";

export const SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS";

export const COLLATERALS_ERROR = "COLLATERALS_ERROR";

export const GET_ALL_COLLATERALS = "GET_ALL_COLLATERALS";
export const GET_COLLATERALS_BY_DATE = "GET_COLLATERALS_BY_DATE";

export const GET_DETACHED_COLLATERALS = "GET_DETACHED_COLLATERALS";
