import {
  ASSIGN_USER_TO_ROLE,
  REMOVE_USER_TO_ROLE,
  CLOSE_DAY,
  CREATE_USER,
  GET_ALL_USERS,
  GET_USER_ROLES,
  OPEN_DAY,
  USERS_LIST_ERROR,
  USERS_LIST_REQUEST,
  IS_DAY_OPEN,
  REGISTER_CUSTOMER_USER,
  UPDATE_USER,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  RESET_USER
} from "./usersListTypes";

const initialState = {
  usersList: [],
  user: {},
  userRole: {},
  userRoles: [],
  roleClaims: [],
  customerUserAdded: null,
  dayOpened: null,
  isDayOpen: false,
  dayClosed: false,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case USERS_LIST_REQUEST:
    case UPDATE_USER:
      return { ...state, loading: true };
    case CREATE_USER:
      return { ...state, user: payload, loading: false };
    case GET_ALL_USERS:
      return { ...state, usersList: payload, loading: false };
    case ASSIGN_USER_TO_ROLE:
      return { ...state, userRole: payload, loading: false };
    case REMOVE_USER_TO_ROLE:
      return { ...state, userRole: payload, loading: false };
    case GET_USER_ROLES:
      return { ...state, userRoles: payload, loading: false };
    case CLOSE_DAY:
      return { ...state, dayClosed: payload, loading: false };
    case OPEN_DAY:
      return { ...state, loading: false, dayOpened: payload };
    case IS_DAY_OPEN:
      return { ...state, loading: false, isDayOpen: payload };
    case REGISTER_CUSTOMER_USER:
      return { ...state, loading: false, customerUserAdded: payload };
    case USERS_LIST_ERROR:
      return { ...state, loading: false, error: payload };
    case RESET_PASSWORD:
      return { ...state, loading: false, };
    case RESET_USER:
      return initialState
    case CHANGE_PASSWORD:
      return { ...state, loading: false, };
    default:
      return state;
  }
}
