import {
	SECURITY_TYPE_ERROR,
	SECURITY_TYPE_REQUEST, CREATE_SECURITY_TYPE,
	GET_SECURITY_TYPE,
	GET_SECURITY_TYPE_BY_ID,
	GET_SECURITY_TYPE_BY_LOAN_ID
} from "./securityTypeTypes";

const initialState = {
	securityType: {},
	allSecurityTypes: [],
	securityTypeById: [],
	securityTypeByLoanId: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SECURITY_TYPE_REQUEST:
			return {
				...state,
				loading: false,
			};
		case CREATE_SECURITY_TYPE:
			return {
				...state,
				securityType: payload,
				loading: false,
			};
		case GET_SECURITY_TYPE:
			return {
				...state,
				allSecurityTypes: payload,
				loading: false,
			};
			case GET_SECURITY_TYPE_BY_ID:
			return {
				...state,
				securityTypeById: payload,
				loading: false,
			};
			case GET_SECURITY_TYPE_BY_LOAN_ID:
			return {
				...state,
				securityTypeByLoanId: payload,
				loading: false,
			};
		case SECURITY_TYPE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
