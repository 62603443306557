export const ACCOUNTING_REQUEST = "ACCOUNTING_REQUEST";
export const ACCOUNTING_ERROR = "ACCOUNTING_ERROR";
export const CLEAR_TELLER_ACTIVITIES_STATE = "CLEAR_TELLER_ACTIVITIES_STATE";

export const GET_CASH_TRANSFER_DETAILS = "GET_CASH_TRANSFER_DETAILS";
export const GET_ALL_LEDGER_OPENING_BALANCE = "GET_ALL_LEDGER_OPENING_BALANCE";
export const GET_LEDGER_OPENING_BALANCE = "GET_LEDGER_OPENING_BALANCE";
export const CREATE_LEDGER_OPENING_BALANCE = "CREATE_LEDGER_OPENING_BALANCE";
export const UPDATE_LEDGER_OPENING_BALANCE = "UPDATE_LEDGER_OPENING_BALANCE";
export const CREATE_CASH_TRANSFER = "CREATE_CASH_TRANSFER";
export const GET_TELLER_DEPOSIT_CORRECTION = "GET_TELLER_DEPOSIT_CORRECTION";
export const CREATE_TELER_DEPOSIT_CORRECTION = "CREATE_TELER_DEPOSIT_CORRECTION";
export const GET_TELLER_SHORTAGES_EXCESS = "GET_TELLER_SHORTAGES_EXCESS";
export const GET_LEDGER_TRANSACTION_LIST = "GET_LEDGER_TRANSACTION_LIST";
export const GET_TELLER_TRANSACTION_REPORT = "GET_TELLER_TRANSACTION_REPORT";
export const DELETE_TELLER_DEPOSIT = "DELETE_TELLER_DEPOSIT";
export const CREATE_RECEIVER_TELLER_SHORTAGE = "CREATE_RECEIVER_TELLER_SHORTAGE";
export const GET_TELLER_TRANSACTION = "GET_TELLER_TRANSACTION";

