/* eslint-disable import/no-anonymous-default-export */

import {
	CREATE_SMS_POINT,
	GET_ALL_SMS_POINT,
	GET_ALL_UNSENT_SMS,
	SEND_BULK_SMS,
	SMS_POINT_ERROR,
	SMS_POINT_REQUEST,
	UPDATE_SMS_POINT,
} from "./smsPointTypes";

const initialState = {
	smsPoint: [],
	allSMSPoint: [],
	unsentSMS: [],
	bulkSMS: {},
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SMS_POINT_REQUEST:
			return { ...state, loading: true };
		case CREATE_SMS_POINT:
		case UPDATE_SMS_POINT:
			return {
				...state,
				smsPoint: payload,
				loading: false,
			};
		case GET_ALL_SMS_POINT:
			return {
				...state,
				allSMSPoint: payload,
				loading: false,
			};
		case GET_ALL_UNSENT_SMS:
			return {
				...state,
				unsentSMS: payload,
				loading: false,
			};
		case SEND_BULK_SMS:
			return {
				...state,
				bulkSMS: payload,
				loading: false,
			};
		case SMS_POINT_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
}
