import { 
  CREATE_LOAN_RESTRICTION, 
  LOAN_RESTRICTION_ERROR,
  LOAN_RESTRICTION_REQUEST,
  GET_RESTRICTED_MEMBERS 
} from "./loaningRestrictionTypes";

const initialState = {
  loanRemarks: null,
  restrictedMembers: null,
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAN_RESTRICTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case CREATE_LOAN_RESTRICTION:
      return {
        ...state,
        loanRemarks: payload,
        loading: false
      }
    case GET_RESTRICTED_MEMBERS:
      return {
        ...state,
        restrictedMembers: payload,
        loading: false
      }
    case LOAN_RESTRICTION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      }
    default:
      return state;
  }
}