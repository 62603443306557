import { 
	GET_DEPOSIT_PRODUCT, CLEAR_MEMBER_BALANCE,
	GET_MEMBER_BALANCE, MEMBER_BALANCE_ERROR, MEMBER_BALANCE_REQUEST } from "./memberBalanceTypes";

const initialState = {
	memberBalance:{},
	loading: false,
	error: null,
	depositProducts: [],
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case MEMBER_BALANCE_REQUEST:
			return {
				...state,
				loading: false,
			};

		case GET_MEMBER_BALANCE:
			return {
				...state,
				memberBalance: payload,
				loading: false,
			};
		case GET_DEPOSIT_PRODUCT:
			return {
				...state,
				depositProducts: payload,
				loading: false
			};
		case MEMBER_BALANCE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case CLEAR_MEMBER_BALANCE:
			return initialState
		default:
			return state;
	}
}
