/* eslint-disable import/no-anonymous-default-export */

import {
	ADD_BANKING_AGENT,
	BANKING_AGENT_ERROR,
	BANKING_AGENT_REQUEST,
	GET_ALL_BANKING_AGENTS,
	UPDATE_BANKING_AGENT,
} from "./bankingAgentTypes";

const initialState = {
	bankingAgent: [],
	bankingAgents: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case BANKING_AGENT_REQUEST:
			return { ...state, loading: true };
		case ADD_BANKING_AGENT:
		case UPDATE_BANKING_AGENT:
			return {
				...state,
				bankingAgent: payload,
				loading: false,
			};
		case GET_ALL_BANKING_AGENTS:
			return {
				...state,
				bankingAgents: payload,
				loading: false,
			};
		case BANKING_AGENT_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
}
