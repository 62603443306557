import {
  CHART_OF_ACCOUNT_ERROR,
  CHART_OF_ACCOUNT_REQUEST,
  CREATE_CHART_OF_ACCOUNT,
  GET_ALL_CHART_OF_ACCOUNT,
  GET_ALL_FILTER_LEVELII_COA,
  GET_COA_FOR_CASH_AND_BANK,
  UPDATE_CHART_OF_ACCOUNT,
} from "./chartofaccountTypes";

const initialState = {
  chartofaccountcreate: "",
  allChartofAccount: [],
  allFilterLevelTwo: [],
  coaCashAndBank: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHART_OF_ACCOUNT_REQUEST:
      return { ...state, loading: true };
    case CREATE_CHART_OF_ACCOUNT:
    case UPDATE_CHART_OF_ACCOUNT:
      return {
        ...state,
        chartofaccountcreate: payload,
        loading: false,
      };
    case GET_ALL_CHART_OF_ACCOUNT:
      return {
        ...state,
        allChartofAccount: payload,
        loading: false,
      };

    case GET_ALL_FILTER_LEVELII_COA:
      return {
        ...state,
        loading: false,
        allFilterLevelTwo: payload,
      };
    case GET_COA_FOR_CASH_AND_BANK:
      return {
        ...state,
        coaCashAndBank: payload,
        loading: false,
      };
    case CHART_OF_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
