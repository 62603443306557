/* eslint-disable import/no-anonymous-default-export */

import { 
	ADD_REGION, GET_ALL_REGIONS, REGION_ERROR, 
	REGION_REQUEST, UPDATE_REGION, GET_BANK_DETAILS 
} from "./regionTypes";

const initialState = {
	region: [],
	bankDetails: [],
	allRegions: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case REGION_REQUEST:
			return { ...state, loading: true };
		case ADD_REGION:
		case UPDATE_REGION:
			return {
				...state,
				region: payload,
				loading: false,
			};
		case GET_ALL_REGIONS:
			return {
				...state,
				allRegions: payload,
				loading: false,
			};
		case REGION_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_BANK_DETAILS:
			return {
				...state,
				bankDetails: payload,
				loading: false
			}

		default:
			return state;
	}
}
