import { closeModal, showModal } from "../../../../components/Modal/modalActions";
import { removeToastAlert, toastAlert } from "../../../../components/Toast/toastAction";
import { axiosInstance } from "../../../../util/axiosInstance";
import { clearRoles } from "../UserRoles/usersRolesActions";
import { LOGIN_REQUEST, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT } from "./loginTypes";

//Login user
export const userLoginRequest = () => {
  return { type: LOGIN_REQUEST };
};

export const userLoginSuccess = (user) => {
  return { type: LOGIN_SUCCESS, payload: user };
};

export const userLoginFailed = (error) => {
  return {
    type: LOGIN_FAIL,
    payload: error,
  };
};

export const login = (user) => async (dispatch) => {
  dispatch(userLoginRequest());
  try {
    const response = await axiosInstance.post("/UserManagement/login", user);
    if (!response.data.isAuthenticated) {
      dispatch(userLoginFailed(response.data.message));
      dispatch(toastAlert("Invalid credentials", "danger"));
    } else {
      dispatch(userLoginSuccess(response.data));
      //   dispatch(showModal("", "OPEN_DAY", "Open Day"));
    }
  } catch (error) {
    dispatch(userLoginFailed(error));
    dispatch(toastAlert("Failed to login", "danger"));
  }
};

//Logout
const userLoggedOut = () => ({
  type: LOGOUT,
});

export const logout = () => (dispatch) => {
  dispatch(clearRoles());
  dispatch(removeToastAlert());
  dispatch(closeModal());
  dispatch(userLoggedOut());
};
