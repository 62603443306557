import {
  LOAN_TYPE_REQUEST,
  CREATE_LOAN_TYPE,
  LOAN_TYPE_ERROR,
  UPDATE_LOAN_TYPE,
  GET_ALL_LOAN_TYPE,
  GET_LOAN_TYPE_BY_ID,
  GET_LOAN_CHARGES,
  GET_LOAN_CALCULATOR
} from "./loanTypeTypes";

const initialState = {
  loanCharges: 0,
  loanCalculator: [],
  getLoanTypes: [],
  getLoanTypesById: [],
  isLoanTypeCreated: false,
  loanTypes: [],
  loading: true,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAN_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        isLoanTypeCreated: false,
      };
    case CREATE_LOAN_TYPE:
    case UPDATE_LOAN_TYPE:
      return {
        ...state,
        loanTypes: payload,
        isLoanTypeCreated: true,
        loading: false,
      };
    case GET_ALL_LOAN_TYPE:
      return {
        ...state,
        getLoanTypes: payload,
        loading: false,
      };
    case GET_LOAN_CHARGES:
      return {
        ...state,
        loanCharges: payload,
        loading: false
      };
    case GET_LOAN_CALCULATOR:
      return {
        ...state,
        loanCalculator: payload,
        loading: false
      }
    case GET_LOAN_TYPE_BY_ID:
      return {
        ...state,
        getLoanTypesById: payload,
        loading: false,
      };
    case LOAN_TYPE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
