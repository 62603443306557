export const CHART_OF_ACCOUNT_REQUEST = "CHART_OF_ACCOUNT_REQUEST";

export const CREATE_CHART_OF_ACCOUNT = "CREATE_CHART_OF_ACCOUNT";
export const GET_ALL_FILTER_LEVELII_COA = "GET_ALL_FILTER_LEVELII_COA";
export const UPDATE_CHART_OF_ACCOUNT = "UPDATE_CHART_OF_ACCOUNT";

export const GET_ALL_CHART_OF_ACCOUNT = "GET_ALL_CHART_OF_ACCOUNT";
export const GET_COA_FOR_CASH_AND_BANK = "GET_COA_FOR_CASH_AND_BANK";

export const CHART_OF_ACCOUNT_ERROR = "CHART_OF_ACCOUNT_ERROR";
