export const CUSTOMER_ACCOUNT_REQUEST = "CUSTOMER_ACCOUNT_REQUEST";
export const CREATE_CUSTOMER_ACCOUNT = "CREATE_CUSTOMER_ACCOUNT";
export const UPDATE_CUSTOMER_ACCOUNT = "UPDATE_CUSTOMER_ACCOUNT";
export const CUSTOMER_ACCOUNT_ERROR = "CUSTOMER_ACCOUNT_ERROR";
export const GET_ALL_CUSTOMERS_ACCOUNT = "GET_ALL_CUSTOMERS_ACCOUNT";
export const GET_SINGLE_CUSTOMERS_ACCOUNT = "GET_SINGLE_CUSTOMERS_ACCOUNT";
export const UPDATE_CUSTOMERS_ACCOUNT_STATUS = "UPDATE_CUSTOMERS_ACCOUNT_STATUS";
export const TOGGLE_MEMBER_TABS = "TOGGLE_MEMBER_TABS";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const GET_ALL_ACCOUNTS_BY_DATE = "GET_ALL_ACCOUNTS_BY_DATE";
export const UPLOAD_MEMBER_DOCUMENTS = "UPLOAD_MEMBER_DOCUMENTS";
export const GET_MEMBER_DOCUMENTS = "GET_MEMBER_DOCUMENTS";
export const GET_PASSPORT_PHOTO = "GET_PASSPORT_PHOTO";
export const CLEAR_MEMBER_DETAILS = "CLEAR_MEMBER_DETAILS";
export const GET_DOCUMENTS_CATEGORY = "GET_DOCUMENTS_CATEGORY";
export const SEARCHED_CUSTOMER = "SEARCHED_CUSTOMER";
export const RESET_CUSTOMER_ACCOUNT = "RESET_CUSTOMER_ACCOUNT";
