export const CASH_RECEIPT_REQUEST = "CASH_RECEIPT_REQUEST";
export const CREATE_CASH_RECEIPT = "CREATE_CASH_RECEIPT";
export const GET_CASH_RECEIPT_DETAILS = "GET_CASH_RECEIPT_DETAILS";
export const CREATE_CASH_RECEIVED_LIST = "CREATE_CASH_RECEIVED_LIST";
export const CASH_RECEIPT_ERROR = "CASH_RECEIPT_ERROR";
export const GET_CASH_TRANSFER_DETAILS = "GET_CASH_TRANSFER_DETAILS";
export const ENABLE_BUTTON = "ENABLE_BUTTON";
export const GET_ALL_TELLERS = "GET_ALL_TELLERS";
export const GET_COA_FOR_TREASURY = "GET_COA_FOR_TREASURY";
export const GET_BANK_BALANCE = "GET_BANK_BALANCE";
export const GET_TREASURY_BALANCE = "GET_TREASURY_BALANCE";
export const GET_TELLER_BALANCE = "GET_TELLER_BALANCE";
