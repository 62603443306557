/* eslint-disable import/no-anonymous-default-export */
import {
	COMPANY_DIRECTOR_ERROR,
	COMPANY_DIRECTOR_REQUEST,
	CREATE_COMPANY_DIRECTOR,
	GET_COMPANY_DIRECTORS_BY_ACCOUNT_NUMBER,
	UPDATE_COMPANY_DIRECTOR,
} from "./companyDirectorTypes";

const initialState = {
	companyDirector: [],
	companyDirectorsByAccountNumber: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case COMPANY_DIRECTOR_REQUEST:
			return { ...state, loading: true };
		case CREATE_COMPANY_DIRECTOR:
		case UPDATE_COMPANY_DIRECTOR:
			return {
				...state,
				companyDirector: payload,
				loading: false,
			};
		case GET_COMPANY_DIRECTORS_BY_ACCOUNT_NUMBER:
			return {
				...state,
				companyDirectorsByAccountNumber: payload,
				loading: false,
			};
		case COMPANY_DIRECTOR_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
}
