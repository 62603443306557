import { closeCollapse } from "../../../../components/Collapse/collapseActions";
import { toastAlert } from "../../../../components/Toast/toastAction";
import { axiosInstance } from "../../../../util/axiosInstance";
import {
  ADD_RIGHTS_TO_ROLE,
  ADD_USER_ROLE,
  GET_ALL_USERS_ROLES,
  GET_ROLE_CLAIMS,
  USERS_ROLES_ERROR,
  USERS_ROLES_REQUEST,
  CLEAR_ROLES,
} from "./usersRolesTypes";

export const usersRolesRequest = () => {
  return {
    type: USERS_ROLES_REQUEST,
  };
};

export const usersRolesError = () => {
  return {
    type: USERS_ROLES_ERROR,
  };
};

// clear roles
export const clearRoles = () => {
  return {
    type: CLEAR_ROLES,
  };
}

//create user
export const userRoleRegistrationSuccess = (user) => {
  return {
    type: ADD_USER_ROLE,
    payload: user,
  };
};

export const addUserRole = (roleName) => async (dispatch) => {
  dispatch(usersRolesRequest());
  try {
    const response = await axiosInstance.post(`/UserManagement/addrole?roleName=${roleName}`);
    dispatch(userRoleRegistrationSuccess({ success: true }));
    dispatch(toastAlert("User Role added  successfully", "success"));
    dispatch(closeCollapse());
    dispatch(getAllUsersRoles());
  } catch (error) {
    dispatch(usersRolesError(error));
    dispatch(toastAlert("Failed to add user", "danger"));
  }
};

// get all users
export const getAllUsersRolesSuccess = (allUsersRoles) => {
  return { type: GET_ALL_USERS_ROLES, payload: allUsersRoles };
};

export const getAllUsersRoles = () => async (dispatch) => {
  dispatch(usersRolesRequest());
  try {
    const response = await axiosInstance.get("/UserManagement/getroles");
    dispatch(getAllUsersRolesSuccess(response.data));
  } catch (error) {
    dispatch(usersRolesError(error));
  }
};

export const getRoleClaimsSuccess = (userRoles) => {
  return { type: GET_ROLE_CLAIMS, payload: userRoles };
};

export const getRoleClaims = (roleId) => async (dispatch) => {
  dispatch(usersRolesRequest());
  try {
    const response = await axiosInstance.get(`/UserManagement/getroleclaims?roleId=${roleId}`);
    dispatch(getRoleClaimsSuccess(response.data));
  } catch (error) {
    dispatch(usersRolesError(error));
  }
};

// addrightstoroles
export const addRightsToRolesSuccess = (user) => {
  return {
    type: ADD_RIGHTS_TO_ROLE,
    payload: user,
  };
};

export const addRightsToRoles = (rights, roleId) => async (dispatch) => {
  dispatch(usersRolesRequest());
  try {
    const response = await axiosInstance.post("/UserManagement/addrightstoroles", rights);
    dispatch(addRightsToRolesSuccess({ success: true }));
    dispatch(toastAlert("Rights Updated  successfully", "success"));
    dispatch(closeCollapse());
    dispatch(getRoleClaims(roleId));
  } catch (error) {
    dispatch(usersRolesError(error));
    dispatch(toastAlert("Failed to add rights", "danger"));
  }
};
