import {
	ACCOUNT_BUDGET_ERROR,
	ACCOUNT_BUDGET_REQUEST,
	CREATE_ACCOUNT_BUDGET,
	UPDATE_ACCOUNT_BUDGET,
	GET_ACCOUNT_BUDGET,
	GET_LEDGER_COMPARISON,
} from "./accountBudgetTypes";

const initialState = {
	accountBudget: {},
	allAccountBudget: [],
	ledgerComparison: [],
	loading: false,
	error: null,
};

export default function (state = initialState, actions) {
	const { type, payload } = actions;

	switch (type) {
		case ACCOUNT_BUDGET_REQUEST:
			return { ...state, loading: true };
		case CREATE_ACCOUNT_BUDGET:
		case UPDATE_ACCOUNT_BUDGET:
			return { ...state, accountBudget: payload, loading: false };
		case GET_ACCOUNT_BUDGET:
			return { ...state, loading: false, allAccountBudget: payload };
		case GET_LEDGER_COMPARISON:
			return { ...state, loading: false, ledgerComparison: payload };
		case ACCOUNT_BUDGET_ERROR:
			return { ...state, loading: false };
		default:
			return state;
	}
}
