import {
  ADD_RIGHTS_TO_ROLE,
  ADD_USER_ROLE,
  CLEAR_ROLES,
  GET_ALL_USERS_ROLES,
  GET_ROLE_CLAIMS,
  USERS_ROLES_ERROR,
  USERS_ROLES_REQUEST,
} from "./usersRolesTypes";

const initialState = {
  usersRoles: [],
  userRole: {},
  roleClaims: null,
  rightsToRole: {},
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case USERS_ROLES_REQUEST:
      return { ...state, loading: true };
    case ADD_USER_ROLE:
      return {
        ...state,
        userRole: payload,
        loading: false,
      };
    case ADD_RIGHTS_TO_ROLE:
      return { ...state, rightsToRole: payload, loading: false };
    case GET_ALL_USERS_ROLES:
      return {
        ...state,
        usersRoles: payload,
        loading: false,
      };
    case GET_ROLE_CLAIMS:
      return { ...state, roleClaims: payload, loading: false };
    case USERS_ROLES_ERROR:
      return { ...state, loading: false, error: payload };
    case CLEAR_ROLES: 
      return {
        usersRoles: [],
        userRole: {},
        roleClaims: null,
        rightsToRole: {},
        loading: false,
        error: null,
      }
    default:
      return state;
  }
}
