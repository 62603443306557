/* eslint-disable import/no-anonymous-default-export */
import {
	CREATE_NON_CASH_LOAN_CORRECTION,
	GET_NON_CASH_LOAN_CORRECTION_BY_ACCOUNT_NUMBER,
	GET_NON_CASH_LOAN_CORRECTION_CALCULATION,
	NON_CASH_LOAN_CORRECTION_ERROR,
	NON_CASH_LOAN_CORRECTION_REQUEST,
	GET_CORRECTION_DETAILS_NON_CASH,
	CREATE_CORRECTION_DETAIL_NON_CASH,
	GET_CORRECTION_NON_CASH_DETAILS,
	RESET_NON_CASH_LOAN_CORRECTION
} from "./nonCashLoanCorrectionTypes";

const initialState = {
	nonCashLoanCorrection: null,
	nonCashLoanCorrectionByAccNumber: [],
	nonCashLoanCorrectionCalculation: null,
	correctionDetailsNonCash: null,
	createdCorrectionDetailNonCash: null,
  correctionNonCashDetails:[],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case NON_CASH_LOAN_CORRECTION_REQUEST:
			return { ...state, loading: true };
		case CREATE_NON_CASH_LOAN_CORRECTION:
			return {
				...state,
				nonCashLoanCorrection: payload,
				loading: false,
			};
		case GET_NON_CASH_LOAN_CORRECTION_BY_ACCOUNT_NUMBER:
			return {
				...state,
				nonCashLoanCorrectionByAccNumber: payload,
				loading: false,
			};
		case GET_NON_CASH_LOAN_CORRECTION_CALCULATION:
			return {
				...state,
				nonCashLoanCorrectionCalculation: payload,
				loading: false,
			};
		case GET_CORRECTION_DETAILS_NON_CASH:
			return {
				...state,
				correctionDetailsNonCash: payload,
				loading: false,
			};
		case CREATE_CORRECTION_DETAIL_NON_CASH:
			return {
				...state,
				createdCorrectionDetailNonCash: payload,
				loading: false,
			};
		case GET_CORRECTION_NON_CASH_DETAILS:
			return {
				...state,
				correctionNonCashDetails: payload,
				loading: false,
			};
		case NON_CASH_LOAN_CORRECTION_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case RESET_NON_CASH_LOAN_CORRECTION:
			return {
				...state,
				nonCashLoanCorrection: null,
				nonCashLoanCorrectionByAccNumber: [],
				nonCashLoanCorrectionCalculation: null,
				correctionDetailsNonCash: null,
				createdCorrectionDetailNonCash: null,
				correctionNonCashDetails:[],
				loading: false,
				error: null,
			};
		default:
			return state;
	}
}
