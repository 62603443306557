export const NON_CASH_DEPOSIT_REQUEST = "NON_CASH_DEPOSIT_REQUEST";

export const GET_NON_CASH_DEPOSIT_BALANCE = "GET_NON_CASH_DEPOSIT_BALANCE";
// export const GET_NON_CASH_DEPOSIT_BY_ACCOUNT_NUMBER = "GET_NON_CASH_DEPOSIT_BY_ACCOUNT_NUMBER";
export const GET_NON_CASH_DEPOSIT_AFTER_PAYMENT = "GET_NON_CASH_DEPOSIT_AFTER_PAYMENT";
export const GET_NON_CASH_DEPOSIT_LOAN_DETAILS = "GET_NON_CASH_DEPOSIT_LOAN_DETAILS";
export const CREATE_NON_CASH_DEPOSIT_LOAN_PAYMENT = "CREATE_NON_CASH_DEPOSIT_LOAN_PAYMENT";
export const CREATE_NON_CASH_INTER_TRANSFER = "CREATE_NON_CASH_INTER_TRANSFER";
export const GET_SUBTRACTION_ADDITION_CALCULATION = "GET_SUBTRACTION_ADDITION_CALCULATION";

export const NON_CASH_DEPOSIT_ERROR = "NON_CASH_DEPOSIT_ERROR";
export const CLEAR_NON_CASH_DEPOSIT = "CLEAR_NON_CASH_DEPOSIT";