/* eslint-disable import/no-anonymous-default-export */
import { 
  CREATE_STANDING_ORDER, 
  GET_ACTIVE_STANDING_ORDER, 
  GET_ALL_STANDING_ORDERS,
  STANDING_ORDER_ERROR, 
  STANDING_ORDER_REQUEST,
  RESET_STANDING_ORDER,
  STANDING_SERVICE
} from "./standingOrdersTypes";

const initialState = {
  standingOrder: "",
  activeStandingOrders: [],
  standingOrders: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STANDING_ORDER_REQUEST:
      return { ...state, loading: true };
    case STANDING_SERVICE: 
      return {
        ...state,
        loading: false
      }
    case CREATE_STANDING_ORDER:
      return {
        ...state,
        standingOrder: payload,
        loading: false,
      };
    case GET_ACTIVE_STANDING_ORDER:
    	return {
    		...state,
    		activeStandingOrders: payload,
    		loading: false,
    	};
    case GET_ALL_STANDING_ORDERS:
      return {
        ...state,
        standingOrders: payload,
        loading: false,
      };
    case STANDING_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case RESET_STANDING_ORDER:
      return {
        ...state,
        standingOrder: "",
        activeStandingOrders: [],
        loading: false,
        error: null,
      }
    default:
      return state;
  }
}
