import { combineReducers } from "redux";
import dividendComputationReducer from "../pages/BOSA/DividendProcessing/DividendComputation/dividendComputationReducer";
import {
	loginReducer,
	collapseReducers,
	modalReducers,
	toastReducer,
	companyDetailsReducer,
	branchDetailReducer,
	employerDetailsReducer,
	countryReducer,
	regionReducer,
	sharesProductReducer,
	depositProductReducer,
	savingProductReducer,
	loanTypeReducer,
	loanChargeReducer,
	loanQualificationReducer,
	loanRecoveryReducer,
	loanBudgetReducer,
	securityTypeReducer,
	accountPurposeReducer,
	incomeRangeReducer,
	accountDesignationReducer,
	documentInfoReducer,
	chequeTypeReducer,
	levelICOAReducer,
	levelIICOAReducer,
	levelIIICOAReducer,
	chartofaccountReducer,
	customerAccountReducer,
	nextOfKinReducer,
	accountSignatoryReducer,
	groupMemberReducer,
	companyDirectorReducer,
	loanRegisterReducer,
	memberBalanceReducer,
	loanGuarantorReducer,
	mobileBankingReducer,
	smsPointReducer,
	collateralTypesReducer,
	collateralReducer,
	bankingAgentReducer,
	posDeviceReducer,
	usersReducer,
	usersRolesReducer,
	reportsReducer,
	remittanceReducer,
	nonCashDepositReducer,
	nonCashLoanCorrectionReducer,
	smsRegistrationReducer,
	cashReceiptReducer,
	standingOrderReducer,
	productManagementReducer,
	batchListingReducer,
	accountingReportReducer,
	tellerActivitiesReducer,
	accountBudgetReducer,
	loanRestrictionReducer,
	accountStatusDescriptionReducer,
	paybillReducer,
	withdrawalChargesReducer
} from "./index";

export default combineReducers({
	collapseState: collapseReducers,
	modalState: modalReducers,
	toast: toastReducer,
	productManagement: productManagementReducer,

	login: loginReducer,

	companyDetails: companyDetailsReducer,
	branchDetails: branchDetailReducer,

	employerDetails: employerDetailsReducer,
	countries: countryReducer,
	regions: regionReducer,

	sharesProduct: sharesProductReducer,
	depositProducts: depositProductReducer,
	savingProducts: savingProductReducer,
	loanType: loanTypeReducer,
	loanCharge: loanChargeReducer,
	loanQualifications: loanQualificationReducer,
	loanRecovery: loanRecoveryReducer,
	loanBudget: loanBudgetReducer,
	securityTypes: securityTypeReducer,

	accountPurpose: accountPurposeReducer,
	incomeRange: incomeRangeReducer,
	accountDesignation: accountDesignationReducer,
	documentInfo: documentInfoReducer,
	chequeType: chequeTypeReducer,

	customerAccount: customerAccountReducer,
	nextOfKins: nextOfKinReducer,
	accountSignatories: accountSignatoryReducer,
	groupMembers: groupMemberReducer,
	companyDirectors: companyDirectorReducer,
	registeredSMS: smsRegistrationReducer,

	levelICoA: levelICOAReducer,
	levelIICoA: levelIICOAReducer,
	levelIIICoA: levelIIICOAReducer,
	chartofAccounts: chartofaccountReducer,

	loanRegister: loanRegisterReducer,
	memberBalance: memberBalanceReducer,
	loanGuarantor: loanGuarantorReducer,
	withdrawalCharges: withdrawalChargesReducer,

	mobileBanking: mobileBankingReducer,
	smsPoint: smsPointReducer,

	collateralTypes: collateralTypesReducer,
	collaterals: collateralReducer,

	bankingAgents: bankingAgentReducer,
	posDevice: posDeviceReducer,

	users: usersReducer,
	usersRoles: usersRolesReducer,

	remittance: remittanceReducer,
	reports: reportsReducer,

	dividendComputation: dividendComputationReducer,

	nonCashLoanCorrection: nonCashLoanCorrectionReducer,
	nonCashDepositDetails: nonCashDepositReducer,

	cashReceipt: cashReceiptReducer,
	standingOrders: standingOrderReducer,
	batchListing: batchListingReducer,

	accountingReport: accountingReportReducer,
	tellerActivities: tellerActivitiesReducer,
	accountBudget: accountBudgetReducer,

	loanRestriction: loanRestrictionReducer,
	accountStatusDescription: accountStatusDescriptionReducer,
	paybill: paybillReducer
});
