/* eslint-disable import/no-anonymous-default-export */
import {
  BATCH_LISTING_ERROR,
  BATCH_LISTING_REQUEST,
  CREATE_TRANSFER_FROM_TELLER_BANK,
  GET_BATCH_DETAILS,
  GET_BATCH_LISTING,
  CREATE_NON_CASH_DEPOSIT_CORRECTION,
  GET_JOURNAL_DEPOSIT_CORRECTION_DETAILS,
} from "./batchListingTypes";

const initialState = {
  batchDetails: null,
  batchList: [],
  batchTranferFromTellerBank: {},
  nonCashDepositCorrection: {},
  journalDepositCorrectionDetails: null,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BATCH_LISTING_REQUEST:
      return { ...state, loading: true };
    case GET_BATCH_DETAILS:
      return {
        ...state,
        batchDetails: payload,
        loading: false,
      };
    case GET_BATCH_LISTING:
      return {
        ...state,
        batchList: payload,
        loading: false,
      };
    case CREATE_TRANSFER_FROM_TELLER_BANK:
      return {
        ...state,
        batchTranferFromTellerBank: payload,
        loading: false,
      };
    case CREATE_NON_CASH_DEPOSIT_CORRECTION:
      return {
        ...state,
        nonCashDepositCorrection: payload,
        loading: false,
      };
    case GET_JOURNAL_DEPOSIT_CORRECTION_DETAILS:
      return {
        ...state,
        journalDepositCorrectionDetails: payload,
        loading: false,
      };
    case BATCH_LISTING_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
