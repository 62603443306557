/* eslint-disable import/no-anonymous-default-export */
import {
	CREATE_GROUP_MEMBER,
	GET_GROUP_MEMBERS_BY_ACCOUNT_NUMBER,
	GROUP_MEMBERS_ERROR,
	GROUP_MEMBERS_REQUEST,
	UPDATE_GROUP_MEMBER,
} from "./groupMembersTypes";

const initialState = {
	groupMember: [],
	groupMembersByAccountNumber: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case GROUP_MEMBERS_REQUEST:
			return { ...state, loading: true };
		case CREATE_GROUP_MEMBER:
		case UPDATE_GROUP_MEMBER:
			return {
				...state,
				comgroupMemberpanyDirector: payload,
				loading: false,
			};
		case GET_GROUP_MEMBERS_BY_ACCOUNT_NUMBER:
			return {
				...state,
				groupMembersByAccountNumber: payload,
				loading: false,
			};
		case GROUP_MEMBERS_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};

		default:
			return state;
	}
}
