import {
	ACCOUNTING_REPORT_ERROR,
	ACCOUNTING_REPORT_REQUEST,
	GET_SASRA_DEPOSIT_RETURN,
	GET_TRIAL_BALANCE,
} from "./accountingReportTypes";

const initialState = {
	loading: false,
	error: null,
	trialBalance: null,
	sasraDepositReturn: {},
};

export default function (state = initialState, actions) {
	const { payload, type } = actions;

	switch (type) {
		case ACCOUNTING_REPORT_REQUEST:
			return { ...state, loading: true, trialBalance: null };
		case GET_TRIAL_BALANCE:
			return { ...state, trialBalance: payload, loading: false };
		case GET_SASRA_DEPOSIT_RETURN:
			return { ...state, sasraDepositReturn: payload, loading: false };
		case ACCOUNTING_REPORT_ERROR:
			return { ...state, error: payload, loading: false };
		default:
			return state;
	}
}
