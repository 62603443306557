import {
	CREATE_INCOME_RANGE,
	GET_INCOME_RANGES,
	INCOME_RANGES_ERROR,
	INCOME_RANGES_REQUEST,
	UPDATE_INCOME_RANGE,
} from "./incomeRangesTypes";

const initialState = {
	incomeRange: {},
	allIncomeRanges: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case INCOME_RANGES_REQUEST:
			return {
				...state,
				loading: false,
			};
		case CREATE_INCOME_RANGE:
		case UPDATE_INCOME_RANGE:
			return {
				...state,
				incomeRange: [payload, ...state.allIncomeRanges],
				loading: false,
			};
		case GET_INCOME_RANGES:
			return {
				...state,
				allIncomeRanges: payload,
				loading: false,
			};
		case INCOME_RANGES_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
