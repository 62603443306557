import {
	COLLATERALS_ERROR,
	COLLATERALS_REQUEST,
	CREATE_COLLATERAL,
	GET_COLLATERALS,
	UPDATE_COLLATERAL,
	UPDATE_VALIDATE_COLLATERAL,
	UPDATE_RELEASE_COLLATERAL,
	UPDATE_ATTACH_COLLATERAL_TO_LOAN,
	SEARCH_COLLATERAL,
	GET_COLLATERALS_FOR_RELEASE,
	GET_COLLATERALS_FOR_APPROVAL,
	GET_COLLATERALS_FOR_LOAN_ATTACHMENT,
	ATTACH_COLLATERAL_TO_LOAN,
	SEARCH_CUSTOMERS,
	GET_COLLATERAL_BY_LOAN_CODE,
	GET_ALL_COLLATERALS,
	GET_COLLATERALS_BY_DATE,
	GET_DETACHED_COLLATERALS,
} from "./collateralTypes";

const initialState = {
	collateral: {},
	isCollateralSubmitted: false,
	allCollateralsWithNoAccNo: [],
	collateralsByDate: [],
	allCollaterals: [],
	searchCollateral: [],
	approveCollaterals: [],
	releaseCollaterals: [],
	CollateralForLoanAttachment: [],
	attachedCollateral: [],
	customerSearch: [],
	collateralByLoanCode: [],
	detachedCollaterals: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case COLLATERALS_REQUEST:
			return {
				...state,
				loading: false,
				isCollateralSubmitted: false,
			};
		case CREATE_COLLATERAL:
		case UPDATE_COLLATERAL:
		case UPDATE_VALIDATE_COLLATERAL:
		case UPDATE_RELEASE_COLLATERAL:
		case UPDATE_ATTACH_COLLATERAL_TO_LOAN:
			return {
				...state,
				collateral: payload,
				isCollateralSubmitted: true,
				loading: false,
			};
		case GET_DETACHED_COLLATERALS:
			return {
				...state,
				detachedCollaterals: payload,
				loading: false
			}
		case GET_ALL_COLLATERALS:
			return {
				...state,
				allCollateralsWithNoAccNo: payload,
				loading: false
			}
		case GET_COLLATERALS_BY_DATE:
			return {
				...state,
				collateralsByDate: payload,
				loading: false
			}
		case GET_COLLATERALS:
			return {
				...state,
				allCollaterals: payload,
				loading: false,
			};
		case SEARCH_COLLATERAL:
			return {
				...state,
				searchCollateral: payload,
				loading: false,
			};
		case GET_COLLATERALS_FOR_RELEASE:
			return {
				...state,
				releaseCollaterals: payload,
				loading: false,
			};
		case GET_COLLATERALS_FOR_APPROVAL:
			return {
				...state,
				approveCollaterals: payload,
				loading: false,
			};
		case GET_COLLATERALS_FOR_LOAN_ATTACHMENT:
			return {
				...state,
				CollateralForLoanAttachment: payload,
				loading: false,
			};
		case ATTACH_COLLATERAL_TO_LOAN:
			return {
				...state,
				attachedCollateral: payload,
				loading: false,
			};
		case SEARCH_CUSTOMERS:
			return {
				...state,
				customerSearch: payload,
				loading: false,
			};
		case GET_COLLATERAL_BY_LOAN_CODE:
			return {
				...state,
				collateralByLoanCode: payload,
				loading: false,
			};
		case COLLATERALS_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
