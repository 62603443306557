import {
  GET_ALL_REGISTERED_MOBILE_BANKING,
  Get_MOBILE_BANKING_BY_ACCOUNT_NUMBER,
  MOBILE_BANKING_REGISTRATION_ERROR,
  MOBILE_BANKING_REGISTRATION_REQUEST,
  REGISTER_MOBILE_BANKING,
  UPDATE_REGISTERED_MOBILE_BANKING,
} from "./mobileBankingTypes";

const initialState = {
  registerSMS: [],
  allMobileBanking: [],
  mobileBankingByAccountNumber: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MOBILE_BANKING_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_MOBILE_BANKING:
    case UPDATE_REGISTERED_MOBILE_BANKING:
      return {
        ...state,
        registerSMS: [payload, ...state.registerSMS],
        loading: false,
      };
    case Get_MOBILE_BANKING_BY_ACCOUNT_NUMBER:
      return {
        ...state,
        allMobileBanking: payload,
        mobileBankingByAccountNumber: payload,
        loading: false,
      };
    case GET_ALL_REGISTERED_MOBILE_BANKING:
      return {
        ...state,
        allMobileBanking: payload,
        loading: false,
      };
    case MOBILE_BANKING_REGISTRATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
