import {
  CREATE_CUSTOMER_ACCOUNT,
  CUSTOMER_ACCOUNT_ERROR,
  CUSTOMER_ACCOUNT_REQUEST,
  GET_ALL_CUSTOMERS_ACCOUNT,
  GET_SINGLE_CUSTOMERS_ACCOUNT,
  TOGGLE_MEMBER_TABS,
  // TOGGLE_TABS,
  UPDATE_CUSTOMERS_ACCOUNT_STATUS,
  SEARCH_CUSTOMER,
  GET_ALL_ACCOUNTS_BY_DATE,
  UPLOAD_MEMBER_DOCUMENTS,
  GET_MEMBER_DOCUMENTS,
  GET_PASSPORT_PHOTO,
  CLEAR_MEMBER_DETAILS,
  GET_DOCUMENTS_CATEGORY,
  SEARCHED_CUSTOMER,
  RESET_CUSTOMER_ACCOUNT,
} from "./customerAccountTypes";

const initialState = {
  customerDetails: [],
  memberDocuments: [],
  documentsCategory: [],
  isCreatedCustomerAccount: false,
  allCustomersAccount: [],
  singleCustomersAccount: null,
  customerAccountStatus: [],
  searchResults: [],
  searchedCustomer: null,
  accountsByDate: null,
  loading: false,
  error: null,
  tab: "addMember",
  passportPhoto: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        isCreatedCustomerAccount: false,
      };
    case CREATE_CUSTOMER_ACCOUNT:
      return {
        ...state,
        customerDetails: payload,
        isCreatedCustomerAccount: true,
        loading: false,
      };
    case SEARCHED_CUSTOMER:
      return {
        ...state,
        searchedCustomer: payload,
        loading: false
      }
    case GET_ALL_CUSTOMERS_ACCOUNT:
      return {
        ...state,
        allCustomersAccount: payload,
        loading: false,
      };
    case GET_MEMBER_DOCUMENTS:
      return {
        ...state,
        memberDocuments: payload,
        loading: false,
      };
    case GET_PASSPORT_PHOTO:
      return {
        ...state,
        passportPhoto: payload,
        loading: false,
      };
    case GET_SINGLE_CUSTOMERS_ACCOUNT:
      return {
        ...state,
        singleCustomersAccount: payload,
        loading: false,
      };
    case UPDATE_CUSTOMERS_ACCOUNT_STATUS:
      return {
        ...state,
        customersAccountStatus: payload,
        loading: false,
      };
    case SEARCH_CUSTOMER:
      return {
        ...state,
        loading: false,
        searchResults: payload,
      };
    case GET_ALL_ACCOUNTS_BY_DATE:
      return { ...state, accountsByDate: payload, loading: false };
    case CUSTOMER_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case TOGGLE_MEMBER_TABS:
      return { ...state, tab: action.payload };
    case UPLOAD_MEMBER_DOCUMENTS:
      return { ...state, loading: false };
    case GET_DOCUMENTS_CATEGORY:
      return { 
        ...state, 
        documentsCategory: payload,
        loading: false 
      };
    case CLEAR_MEMBER_DETAILS: {
      return {
        ...state,
        customerDetails: [],
        memberDocuments: [],
        isCreatedCustomerAccount: false,
        allCustomersAccount: [],
        singleCustomersAccount: null,
        customerAccountStatus: [],
        searchResults: [],
        accountsByDate: null,
        loading: false,
        error: null,
        tab: "addMember",
        passportPhoto: null,
      }
    }
    default:
      return state;
  }
}
