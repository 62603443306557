import {
  POS_DEVICE_CREATION_REQUEST,
  POS_DEVICE_CREATION_SUCCESS,
  POS_DEVICE_CREATION_FAIL,
  POS_DEVICE_UPDATE,
  GET_ALL_POS_DEVICE_SUCCESS,
  GET_ALL_POS_DEVICE_FAIL,
  UPDATE_POS_DEVICE_STATUS,
  GET_ASSIGNED_POS_DEVICE,
} from "./posDeviceType";

const initialState = {
  Name: "",
  allPOSDevices: [],
  assignedPOSDevices: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POS_DEVICE_CREATION_REQUEST:
      return { ...state, loading: true };
    case POS_DEVICE_CREATION_SUCCESS:
    case POS_DEVICE_UPDATE:
    case UPDATE_POS_DEVICE_STATUS:
      return {
        ...state,
        Name: payload,
        loading: false,
      };
    case POS_DEVICE_CREATION_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_ALL_POS_DEVICE_SUCCESS:
      return {
        ...state,
        allPOSDevices: payload,
        loading: false,
      };
    case GET_ALL_POS_DEVICE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_ASSIGNED_POS_DEVICE:
      return {
        ...state,
        assignedPOSDevices: payload,
        loading: false,
      };
    default:
      return state;
  }
}
