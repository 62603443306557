import {
  CHEQUE_TYPE_ERROR,
  CHEQUE_TYPE_REQUEST,
  CREATE_CHEQUE_TYPE,
  GET_ALL_CHEQUE_TYPE_LIST,
  UPDATE_CHEQUE_TYPE,
} from "./chequeTypeTypes";

const initialState = {
  chequeTypeLists: [],
  chequeType: [],
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHEQUE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CHEQUE_TYPE:
    case UPDATE_CHEQUE_TYPE:
      return {
        ...state,
        chequeType: [payload, ...state.chequeType],
        loading: false,
      };
    case GET_ALL_CHEQUE_TYPE_LIST:
      return {
        ...state,
        chequeTypeLists: payload,
        loading: false,
      };
    case CHEQUE_TYPE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
