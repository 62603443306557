import {
	ACCOUNTING_ERROR,
	ACCOUNTING_REQUEST,
	CLEAR_TELLER_ACTIVITIES_STATE,
	CREATE_CASH_TRANSFER,
	CREATE_LEDGER_OPENING_BALANCE,
	GET_ALL_LEDGER_OPENING_BALANCE,
	GET_CASH_TRANSFER_DETAILS,
	GET_LEDGER_OPENING_BALANCE,
	GET_TELLER_DEPOSIT_CORRECTION,
	GET_TELLER_TRANSACTION,
	UPDATE_LEDGER_OPENING_BALANCE,
	CREATE_TELER_DEPOSIT_CORRECTION,
	GET_TELLER_SHORTAGES_EXCESS,
	GET_LEDGER_TRANSACTION_LIST,
	GET_TELLER_TRANSACTION_REPORT,
	DELETE_TELLER_DEPOSIT,
	CREATE_RECEIVER_TELLER_SHORTAGE,
} from "./tellerActivitiesTypes";

const initialState = {
	cashTransferDetails: {},
	cashTransfer: {},
	allLedgerOpeningBalance: [],
	ledgerOpeningBalance: {},
	createdLedgerOpeningBalance: {},
	updatedLedgerOpeningBalance: {},
	tellerTransaction: [],
	tellerDepositCorrection: [],
	correctedTellerDeposit: {},
	tellerShortagesAndExcess: [],
	ledgerTransactionList: [],
	tellerTransactionReport: [],
	receiverTellerShortage: null,
	transactionDeleted: false,
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case ACCOUNTING_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_CASH_TRANSFER_DETAILS:
			return {
				...state,
				cashTransferDetails: payload,
				loading: false,
			};
		case CREATE_CASH_TRANSFER:
			return {
				...state,
				cashTransfer: payload,
				loading: false,
			};
		case GET_ALL_LEDGER_OPENING_BALANCE:
			return {
				...state,
				allLedgerOpeningBalance: payload,
				loading: false,
			};
		case GET_LEDGER_OPENING_BALANCE:
			return {
				...state,
				ledgerOpeningBalance: payload,
				loading: false,
			};

		case CREATE_LEDGER_OPENING_BALANCE:
			return {
				...state,
				createdLedgerOpeningBalance: payload,
				loading: false,
			};
		case UPDATE_LEDGER_OPENING_BALANCE:
			return {
				...state,
				updatedLedgerOpeningBalance: payload,
				loading: false,
			};
		case GET_TELLER_TRANSACTION:
			return {
				...state,
				tellerTransaction: payload,
				loading: false,
			};
		case CREATE_RECEIVER_TELLER_SHORTAGE:
			return { ...state, receiverTellerShortage: payload };
		case GET_TELLER_DEPOSIT_CORRECTION:
			return {
				...state,
				tellerDepositCorrection: payload,
				loading: false,
			};
		case CREATE_TELER_DEPOSIT_CORRECTION:
			return { ...state, correctedTellerDeposit: payload, loading: false };
		case GET_TELLER_SHORTAGES_EXCESS:
			return { ...state, tellerShortagesAndExcess: payload, loading: false };
		case GET_LEDGER_TRANSACTION_LIST:
			return { ...state, ledgerTransactionList: payload, loading: false };
		case GET_TELLER_TRANSACTION_REPORT:
			return { ...state, tellerTransactionReport: payload, loading: false };
		case DELETE_TELLER_DEPOSIT:
			return { ...state, transactionDeleted: payload, loading: false };
		case ACCOUNTING_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		case CLEAR_TELLER_ACTIVITIES_STATE:
			return initialState;
		default:
			return state;
	}
}
