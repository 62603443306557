export const routes = {
  LOGIN: "/",
  DASHBOARD: "/dashboard",
  ORGANIZATION_SETUP: "organization-setup",
  PRODUCT_MANAGEMENT: "product-management",

  COMPANY_DETAIL: "company-detail",
  BRANCH_DETAIL: "branch-detail",
  BANK_DETAILS: "bank-details",

  EMPLOYER_DETAIL: "employer-details",
  COUNTRY: "country",
  REGION: "region",
  COUNTY: "county",

  WITHDRAWAL_CHARGES: "withdrawal-charges",
  YEARLY_SAVINGS_SERVICE_FEE: "yearly-savings-service-fee",
  SACCO_TREASURY_TRANSFER: "sacco-treasury-transfer",

  SHARES_PRODUCT: "shares-product",
  DEPOSIT_PRODUCT: "deposit-product",
  SAVING_PRODUCT: "saving-product",
  LOAN_TYPE: "loan-type",
  LOAN_CHARGES: "loan-charges",
  LOAN_PURPOSE: "loan-purpose",
  LOAN_QUALIFICATION: "loan-qualification",
  LOAN_RECOVERY: "loan-recovery",
  LOAN_BUDGET: "loan-budget",
  LOAN_SECURITY_TYPE: "loan-security-type",
  LOAN_CAPITALIZATION_MODES: "loan-capitalization-modes",
  ONLINE_APPRAISAL_FACTORING: "online-appraisal-factoring",
  GENERAL_PAYMENT: "general-payment",

  ACCOUNT_CATEGORY: "account-category",
  INCOME_RANGE: "income-range",
  DOCUMENT_INFO: "document-info",
  CHEQUE_TYPE: "cheque-type",
  ACCOUNT_DESIGNATION: "account-designation",

  COA_SETUP: "coa-setup",
  BALANCE_SHEET_LEDGER: "ledger-to-balance-sheet",
  PROFIT_AND_LOSS_LEDGER: "ledger-to-profit-&-loss-items",

  CUSTOMER_ACCOUNT: "customer-account",
  CUSTOMER_USER: "customer-user",
  MEMBERSHIP_ACCOUNT: "member-account",
  LOAN_REGISTRATION: "loan-registration",

  ACCOUNTING_LEVEL_I: "account-level-i",
  ACCOUNTING_LEVEL_II: "account-level-ii",
  ACCOUNTING_LEVEL_III: "account-level-iii",
  CHART_OF_ACCOUNTS: "chart-of-accounts",
  COA_LEDGER_REGISTRATION: "coa-ledger-registration",

  PERSONAL_BANKING: "personal-banking",
  MOBILE_BANKING: "mobile-banking",
  ONLINE_BANKING: "online-banking",
  SMS_POINT: "sms-point",

  COLLATERAL_REGISTRATION: "collateral-registration",
  COLLATERAL_TYPES: "collateral-type",
  CAPTURE_COLLATERAL: "capture-collateral",

  AGENCY_BANKING: "agency-banking",
  BANKING_AGENT: "banking-agent",
  POS_DEVICE: "pos-device",

  USER_MANAGEMENT: "user-management",
  USERS_LIST: "users-list",
  USERS_ROLES: "users-roles",

  LOANING: "loaning",
  LOAN_PROCESSING: "loan-processing",
  LOANING_REPORTS: "loan-reports",
  LOAN_BALANCE_LIST_REPORT: "loan-balance-list",
  LOAN_DISBURSEMENT_REPORT: " loan-disbursement",
  LOAN_STATEMENT_REPORT: "loan-statement",
  LOAN_TYPE_BALANCE: "loan-balances-summary",
  LOANING_REMARK: "loaning-remark",
  LOAN_BALANCE_LIST_WITH_DEPOSIT: "loan-bal-list-with-report",

  PAYMENTS: "payments",
  NON_CASH_LOAN_CORRECTION: "non-cash-loan-correction",
  NON_CASH_DEPOSIT: "non-cash-deposit",
  NON_CASH_SAVING: "non-cash-saving",
  REMITTANCE: "remittance",

  DIVIDENDS_PROCESSING: "dividends-processing",
  DIVIDEND_COMPUTATION: "dividend-computation",
  DIVIDEND_DISBURSEMENT: "dividend-disbursement",
  DISBURSED_DIVIDENDS_REPORT: "disbursed-dividends-report",
  DIVIDENDS_RATES_REVIEW: "dividends-rates-review",

  GUARANTOR_MANAGEMENT: "guarantor-management",
  GUARANTORS_SUBTITUTE: "guarantors_to_substitute",
  RELEASE_GUARANTORS: "release-guarantors",
  GUARANTOR_REPORTS: "guarantor-reports",
  GUARANTOR_SUBTITUTE_FORM: "guarantor-substitute-form",
  GUARANTOR_LOANEE_REPORT: "guarantor-loanee-report",
  LOANEE_GUARANTOR_REPORT: "loanee-guarantor-report",

  REFERRAL_ACCOUNT_REPORT: "referral-account-report",
  ACCOUNT_STATUS_REPORT: "account-status-report",

  POSTING_BANKING_SLIPS_RECEIPTS: "posting-banking-slips-receipts",
  STANDING_ORDERS: "standing-orders",
  BOSA_REPORTS: "bosa-reports",
  LOAN_REPAYMENT: "loan-repayment",
  BANK_SLIP: "bank-slip",

  ACCOUNTING_REPORTS: "accounting-reports",
  WITHDRAWAL: "withdrawal",

  BATCH_LISTING: "batch-listing",
  INDIVIDUAL_DEPOSIT: "posted-deposits-correction",
  DEPOSIT_CHARGES: "deposit-charges",

  LEDGER_OPENING_BALANCE: "ledger-opening-balance",
  ACCOUNT_BUDGET: "account-budget",

  TELLER_ACTIVITIES: "teller-activities",
  CHIEF_TELLER_ACTIVITIES: "chief-teller-activities",
  TREASURY_TO_TELLER: "treasury-to-teller",
  SACCO_MIRROR_BANK: "sacco-mirror-bank",
  CASH_DEPOSIT: "cash-deposits",
  BATCH_CASH_DEPOSIT: "batch-cash-deposits",
  CASH_WITHDRAWAL: "cash-withdrawal",
  TELLER_TREASURY_TRANSACTION: "teller-treasury-transaction",
  TREASURY_BANK_TRANSACTION: "treasury-banktransaction",
  CASH_DEPOSIT_CORRECTION: "cash-deposit-correction",
  TELLER_LIST: "teller-list",
  TELLER_DETAILS: "teller-details",
  CLOSE_DAY: "close-day",
  CHIEF_TELLER_CORRECTION: "chief-teller-correction",
  WITHDRAWAL_CHARGES_ITEM: "withdraw-charges-item",
  FOSA_CASH_DEPOSIT: "fosa-cash-deposit",
  FOSA_REPORT: "fosa-report",
  FOSA_CASH_WITHDRAWAL: "fosa-cash-withdrawal",
  CASH_SAVING_STATEMENT: "cash-saving-statement",
  FOSA_SAVING_LIST: "fosa-saving-list",
  FOSA_GENERAL_PAYMENTS: "fosa-general-payments",

  ACCOUNT_LOAN_DETAILS: "account-loan-details",
  MEMBER_LOAN_REGISTRATION: "member-registered-loans",
  COLLATERAL_ATTACHMENT: "collateral-attachment",
  GUARANTOR_ATTACHMENT: "guarantor-attachment",
  COLLATERAL_LISTING: "collateral-listing",

  JOURNAL_POSTING: "journal-posting",
  NON_CASH_INTER_TRANSFER: "non-cash-inter-transfer",

  DEPOSIT_STATEMENT_REPORT: "deposit-statement-report",
  DEPOSIT_BALANCE_LISTING_REPORT: "deposit-balance-listing-report",
  DAILY_WITHDRAWAL_REPORT: "daily-withdrawal-report",
  CUSTOMER_BALANCE_LIST_REPORT: "customer-balance-list-report",

  TELLER_REPORTS: "teller-reports",
  TELLER_TRANSACTION_LISTING: "teller-transaction-listing",
  TELLER_SHORTAGE_EXCESS: "teller-shortage-excess",
  TREASURY_ACTIVITIES_REPORT: "treasury-activities-report",
  BANK_ACTIVITIES_REPORT: "bank-activities-report",
  TELLER_CLOSE_DAY_TRANSACTIONS: "teller-close-day-transactions",
  DELETE_TELLER_RECORD: "delete-teller-record",

  LOANS_REGISTER: "loans-register",
  ALL_REGISTERED_LOANS: "all-registered-loans",
  LOANS_APPRAISAL: "loan-appraisal",
  LOANS_APPROVAL: "loans-approval",
  LOANS_DISBURSEMENT: "loans-disbursement",
  LOANS_REJECTION: "loans-rejection",
  COMPLETED_LOANS: "completed-loans",
  AMEND_DEFAULTED_LOAN: "amend-defaulted-loan",
  LOAN_RESTRICTION_FORM: "loan-restriction-form",
  AMEND_DEFAULTED_LOAN_FORM: "amend-defaulted-loan-form",
  LOAN_CHARGES_CALCULATION: "loan-charges-calculation",

  ACCOUNT_STATUS_DESCRIPTION: "account-status-description",
  EXTERNAL_EMPLOYER_DETAILS: "external-employer-details",
  RESET_PASSWORD: "reset-password",
  RESTRICTED_MEMBERS: "restricted-members",

  // Internal Activities
  PAYBILL: "paybill",
  PAYBILL_REPORT: "paybill-report",
};
