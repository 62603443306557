import {
	GET_DISBURSED_DIVIDENDS_BY_BRANCH,
	GET_DISBURSED_DIVIDENDS_BY_PRODUCT_CODE,
	GET_DISBURSED_DIVIDENDS_BY_YEAR,
	GET_GUARANTOR_LOANEE,
	GET_LOAN_STATEMENT,
	GET_LOAN_DISBURSEMENT_BY_FILTERS,
	GET_LOAN_GUARANTOR,
	GET_MBANKING_STATUS,
	GET_REFERAL_ACCOUNT,
	GET_STATUS,
	GET_STATUS_BY_DATE,
	REPORT_ERROR,
	REPORT_REQUEST,
	GET_LOAN_BY_STAGES,
	GET_DEPOSIT_BALANCE_LISTING,
	GET_DEPOSIT_STATEMENT,
	GET_LEDGER_SINGLE_ACCOUNT,
	GET_GENERAL_TRANSACTION_AUDIT,
	GET_DEPOSIT_BALANCE_BY_DATE,
	GET_DEPOSIT_BALANCE_LISTING_BETWEEN_DATE,
	GET_DAILY_WITHDRAWAL_REPORT,
	GET_ALL_LOAN_BALANCE_LIST,
	GET_LOAN_TYPE_BALANCE,
	GET_LOAN_BAL_WITH_DEPOSITS,
	GET_SAVINGS_REPORT,
	GET_FOSA_SAVING_REPORT,
	CLEAR_REPORT_STATE
} from "./reportTypes";

const initialState = {
	loading: false,
	referalAccount: [],
	loanBalWithDeposits: [],
	accountStatusByDate: [],
	accountStatus: [],
	mbankingStatus: [],
	disbursedDividendsByYear: [],
	disburseDividendsByProductCode: [],
	totals: [],
	disburseDividendsByPBranch: [],
	guarantorLoanee: [],
	loanGuarantor: [],
	loanDisbursement: [],
	loanStatement: null,
	loanByStages: [],
	depositBalanceListing: null,
	depositBalanceListingBetweenDate: [],
	depositStatement: null,
	ledgerSingleAccount: {},
	generalTransactionAudit: {},
	depositBalanceByDate: [],
	dailyWithdrawalReport: [],
	allLoanBalanceList: [],
	savingsReport: [],
	fosaSavingsReport: [],
	loanTypeBalance:null,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case REPORT_REQUEST:
			return { ...state, loading: true };
		case GET_REFERAL_ACCOUNT:
			return { ...state, referalAccount: payload, loading: false };

		case GET_LOAN_BAL_WITH_DEPOSITS:
			return {
				...state,
				loanBalWithDeposits: payload,
				loading: false
			};
		case GET_SAVINGS_REPORT:
			return {
				...state,
				savingsReport: payload,
				loading: false
			}
		case GET_FOSA_SAVING_REPORT:
			return {
				...state,
				fosaSavingsReport: payload,
				loading: false
			}
		case GET_STATUS_BY_DATE:
			return { ...state, accountStatusByDate: payload, loading: false };
		case GET_STATUS:
			return { ...state, accountStatus: payload, loading: false };
		case GET_MBANKING_STATUS:
			return { ...state, mbankingStatus: payload, loading: false };
		case GET_DISBURSED_DIVIDENDS_BY_YEAR:
			return { ...state, disbursedDividendsByYear: payload, loading: false };
		case GET_DISBURSED_DIVIDENDS_BY_PRODUCT_CODE:
			return {
				...state,
				disburseDividendsByProductCode: payload.shareHistoryList,
				totals: payload.totals,
				loading: false,
			};
		case GET_DISBURSED_DIVIDENDS_BY_BRANCH:
			return { ...state, disburseDividendsByPBranch: payload, loading: false };
		case GET_GUARANTOR_LOANEE:
			return { ...state, guarantorLoanee: payload, loading: false, error: null };
		case GET_LOAN_GUARANTOR:
			return { ...state, loanGuarantor: payload, loading: false, error: null };
		case GET_LOAN_DISBURSEMENT_BY_FILTERS:
			return { ...state, loanDisbursement: payload, loading: false };
		case GET_LOAN_BY_STAGES:
			return { ...state, loanByStages: payload, loading: false };
		case GET_LOAN_STATEMENT:
			return { ...state, loanStatement: payload, loading: false };
		case GET_DEPOSIT_BALANCE_LISTING:
			return { ...state, depositBalanceListing: payload, loading: false };
		case GET_DEPOSIT_BALANCE_LISTING_BETWEEN_DATE:
			return { ...state, depositBalanceListingBetweenDate: payload, loading: false };
		case GET_DEPOSIT_STATEMENT:
			return { ...state, depositStatement: payload, loading: false };
		case GET_LEDGER_SINGLE_ACCOUNT:
			return { ...state, ledgerSingleAccount: payload, loading: false };
		case GET_GENERAL_TRANSACTION_AUDIT:
			return { ...state, generalTransactionAudit: payload, loading: false };
		case GET_DEPOSIT_BALANCE_BY_DATE:
			return { ...state, depositBalanceByDate: payload, loading: false };
		case GET_DAILY_WITHDRAWAL_REPORT:
			return { ...state, dailyWithdrawalReport: payload, loading: false };
		case GET_ALL_LOAN_BALANCE_LIST:
			return { ...state, allLoanBalanceList: payload, loading: false };
			case GET_LOAN_TYPE_BALANCE:
				return {...state, loanTypeBalance:payload, loading:false}
		case REPORT_ERROR:
			return { ...state, error: payload, loading: false };
		case CLEAR_REPORT_STATE:
			return initialState;
		default:
			return state;
	}
}
