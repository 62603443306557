import {
	ACCOUNT_SIGNATORY_ERROR,
	ACCOUNT_SIGNATORY_REQUEST,
	ADD_ACCOUNT_SIGNATORY, GET_ACCOUNT_SIGNATORIES_BY_ACCOUNT_NUMBER
} from "./accountSignatoryTypes";

const initialState = {
	accountSignatory: [],
	accountSignatoriesByAccountNumber: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case ACCOUNT_SIGNATORY_REQUEST:
			return {
				...state,
				loading: false,
			};
		case ADD_ACCOUNT_SIGNATORY:
			return {
				...state,
				nextOfKin: payload,
				loading: false,
			};
		case GET_ACCOUNT_SIGNATORIES_BY_ACCOUNT_NUMBER:
			return {
				...state,
				accountSignatoriesByAccountNumber: payload,
				loading: false,
			};
		case ACCOUNT_SIGNATORY_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}
