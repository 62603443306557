/* eslint-disable import/no-anonymous-default-export */
import {
	CREATE_NON_CASH_DEPOSIT_LOAN_PAYMENT,
	GET_NON_CASH_DEPOSIT_AFTER_PAYMENT,
	GET_NON_CASH_DEPOSIT_BALANCE,
	GET_NON_CASH_DEPOSIT_LOAN_DETAILS,
	NON_CASH_DEPOSIT_ERROR,
	NON_CASH_DEPOSIT_REQUEST,
	CREATE_NON_CASH_INTER_TRANSFER,
  GET_SUBTRACTION_ADDITION_CALCULATION,
	CLEAR_NON_CASH_DEPOSIT,
} from "./nonCashDepositTypes";

const initialState = {
	nonCashDepositBalance: null,
	nonCashDepositAfterPayment: null,
	nonCashDepositDetails: null,
	nonCahsDepositLoanPayment: null,
	nonCashInterTransfer: {},
	subtractionAdditionCalculation: null,
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case NON_CASH_DEPOSIT_REQUEST:
			return { ...state, loading: true };
		case GET_NON_CASH_DEPOSIT_BALANCE:
			return {
				...state,
				nonCashDepositBalance: payload,
				loading: false,
			};
		case GET_NON_CASH_DEPOSIT_AFTER_PAYMENT:
			return {
				...state,
				nonCashDepositAfterPayment: payload,
				loading: false,
			};
		case GET_NON_CASH_DEPOSIT_LOAN_DETAILS:
			return {
				...state,
				nonCashDepositDetails: payload,
				loading: false,
			};
		case CREATE_NON_CASH_DEPOSIT_LOAN_PAYMENT:
			return {
				...state,
				nonCahsDepositLoanPayment: payload,
				loading: false,
			};
		case CREATE_NON_CASH_INTER_TRANSFER:
			return { ...state, nonCashInterTransfer: payload, loading: false };
		case GET_SUBTRACTION_ADDITION_CALCULATION:
			return { ...state, subtractionAdditionCalculation: payload, loading: false };
		case NON_CASH_DEPOSIT_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case CLEAR_NON_CASH_DEPOSIT:
			return initialState;
		default:
			return state;
	}
}
