import {
	COMPANY_DETAILS_CREATION_REQUEST,
	COMPANY_DETAILS_CREATION_SUCCESS,
	COMPANY_DETAILS_CREATION_FAIL,
	COMPANY_DETAILS_UPDATE,
	GET_ALL_COMPANY_DETAILS_SUCCESS,
	GET_ALL_COMPANY_DETAILS_FAIL,
} from "./companyDetailsType";

const initialState = {
	company: [],
	allCompanyDetails: [],
	loading: false,
	error: null,
};
export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case COMPANY_DETAILS_CREATION_REQUEST:
			return { ...state, loading: true };
		case COMPANY_DETAILS_CREATION_SUCCESS:
		case COMPANY_DETAILS_UPDATE:
			return {
				...state,
				company: payload,
				loading: false,
			};
		case COMPANY_DETAILS_CREATION_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_ALL_COMPANY_DETAILS_SUCCESS:
			return {
				...state,
				allCompanyDetails: payload,
				loading: false,
			};
		case GET_ALL_COMPANY_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}