import axios from "axios";

// const baseURL = "http://67.205.144.83:8815/api"; //test
// const baseURL = "http://167.99.249.227:8815/api"; //prod
const baseURL = "https://demo.computercastlesltd.co.ke/api"; //prod

export const axiosInstance = axios.create({
  baseURL,
  headers: { "Content-Type": "application/json", Accept: "application/json", crossDomain: true },
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("cemastoken");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});
