import { COUNTRY_REQUEST } from "../../Administration/Country/countryTypes";
import {
	BRANCH_DETAILS_CREATION_REQUEST,
	BRANCH_DETAILS_CREATION_SUCCESS,
	BRANCH_DETAILS_CREATION_FAIL,
	BRANCH_DETAILS_UPDATE,
	GET_ALL_BRANCH_DETAILS_SUCCESS,
	GET_ALL_BRANCH_DETAILS_FAIL,
	GET_COUNTIES_SUCCESS,
} from "./branchDetailType"

const initialState = {
	branchDetails: [],
	allbranchDetails: [],
	counties: [],
	loading: false,
	countyLoading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case BRANCH_DETAILS_CREATION_REQUEST:
			return { ...state, loading: true };
		case COUNTRY_REQUEST:
			return { ...state, countyLoading: true }
		case GET_COUNTIES_SUCCESS:
			return {
				...state,
				counties: payload,
				countyLoading: false
			}
		case BRANCH_DETAILS_CREATION_SUCCESS:
		case BRANCH_DETAILS_UPDATE:
			return {
				...state,
				branchDetails: payload,
				loading: false,
			};
		case BRANCH_DETAILS_CREATION_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		case GET_ALL_BRANCH_DETAILS_SUCCESS:
			return {
				...state,
				allbranchDetails: payload,
				loading: false,
			};
		case GET_ALL_BRANCH_DETAILS_FAIL:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}