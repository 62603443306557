/* eslint-disable import/no-anonymous-default-export */
import {
  CREATE_EXTRACTED_REMITTANCE,
  CREATE_REMITTANCE,
  REMITTANCE_ERROR,
  REMITTANCE_REQUEST,
  GET_UNSUPPORTED_REMITTANCE,
  SEARCH_REMITTANCE,
  CLEAR_REMITTANCE
} from "./remittanceTypes";

const initialState = {
  remittance: null,
  unsupportedRemittance: null,
  searchedRemittances: null,
  extractedRemittance: {},
  isRemittanceSaved: false,
  loading: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case REMITTANCE_REQUEST:
      return { ...state, loading: true, isRemittanceSaved: false };
    case CREATE_REMITTANCE:
      return {
        ...state,
        remittance: payload,
        loading: false,
      };
    case CREATE_EXTRACTED_REMITTANCE:
      return {
        ...state,
        extractedRemittance: payload,
        isRemittanceSaved: true,
        remittance: null,
        loading: false,
      };
    case GET_UNSUPPORTED_REMITTANCE:
      return {
        ...state,
        unsupportedRemittance: payload,
        loading: false,
      };
    case SEARCH_REMITTANCE:
      return {
        ...state,
        searchedRemittances: payload,
        loading: false,
      };
    case REMITTANCE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case CLEAR_REMITTANCE:
      return {
        initialState
      }
    default:
      return state;
  }
}
