import {
	GET_REGISTERED_SMS_BY_ACCOUNT_NUMBER,
	REGISTER_SMS,
	SMS_REGISTRATION_ERROR,
	SMS_REGISTRATION_REQUEST,
	UPDATE_REGISTERED_SMS,
} from "./smsRegistrationTypes";

const initialState = {
	registerSMS: [],
	registeredSMS: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SMS_REGISTRATION_REQUEST:
			return {
				...state,
				loading: true,
			};
		case REGISTER_SMS:
		case UPDATE_REGISTERED_SMS:
			return {
				...state,
				registerSMS: payload,
				loading: false,
			};
		case GET_REGISTERED_SMS_BY_ACCOUNT_NUMBER:
			return {
				...state,
				registeredSMS: payload,
				loading: false,
			};
		case SMS_REGISTRATION_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}
