/* eslint-disable import/no-anonymous-default-export */
import {
  LEVELI_COA_REQUEST,
  LEVELI_COA_CREATION,
  GET_ALL_LEVELI_COA,
  LEVELI_COA_UPDATE,
  LEVELI_COA_FAIL,
} from "./levelICOATypes";

const initialState = {
  levelICoA: "",
  allLevelICOA: [],
  loading: false,
  error: null,
};
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LEVELI_COA_REQUEST:
      return { ...state, loading: true };
    case LEVELI_COA_CREATION:
    case LEVELI_COA_UPDATE:
      return {
        ...state,
        levelICoA: payload,
        loading: false,
      };

    case GET_ALL_LEVELI_COA:
      return {
        ...state,
        allLevelICOA: payload,
        loading: false,
      };
    case LEVELI_COA_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
}
