export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";
export const CREATE_USER = "CREATE_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const ASSIGN_USER_TO_ROLE = "ASSIGN_USER_TO_ROLE";
export const REMOVE_USER_TO_ROLE = "REMOVE_USER_TO_ROLE";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const OPEN_DAY = "OPEN_DAY";
export const IS_DAY_OPEN = "IS_DAY_OPEN";
export const CLOSE_DAY = "CLOSE_DAY";
export const UPDATE_USER = "UPDATE_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_USER = "RESET_PASSWORD"

export const REGISTER_CUSTOMER_USER = "REGISTER_CUSTOMER_USER";
