export const NON_CASH_LOAN_CORRECTION_REQUEST = "NON_CASH_LOAN_CORRECTION_REQUEST";

export const CREATE_NON_CASH_LOAN_CORRECTION = "CREATE_NON_CASH_LOAN_CORRECTION";
export const GET_NON_CASH_LOAN_CORRECTION_BY_ACCOUNT_NUMBER = "GET_NON_CASH_LOAN_CORRECTION_BY_ACCOUNT_NUMBER";
export const GET_NON_CASH_LOAN_CORRECTION_CALCULATION = "GET_NON_CASH_LOAN_CORRECTION_CALCULATION";
export const GET_CORRECTION_DETAILS_NON_CASH = "GET_CORRECTION_DETAILS_NON_CASH";
export const CREATE_CORRECTION_DETAIL_NON_CASH = "CREATE_CORRECTION_DETAIL_NON_CASH";
export const GET_CORRECTION_NON_CASH_DETAILS = "GET_CORRECTION_NON_CASH_DETAILS";

export const NON_CASH_LOAN_CORRECTION_ERROR = "NON_CASH_LOAN_CORRECTION_ERROR";

export const RESET_NON_CASH_LOAN_CORRECTION = "RESET_NON_CASH_LOAN_CORRECTION";
