import { CLOSE, OPEN } from "./collapseTypes";

// const initialState = {
// 	open: false,
// };

export default function (state = { open: false, id: "" }, action) {
	const { type, payload } = action;

	switch (type) {
		case OPEN:
			return {
				...state,
				open: true,
				id: payload,
			};
		case CLOSE:
			return {
				...state,
				open: false,
			};
		default:
			return state;
	}
}
