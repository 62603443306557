import {
	ADD_NEXT_OF_KIN,
	GET_NEXT_OF_KINS_BY_ACCOUNT_NUMBER,
	NEXT_OF_KIN_ERROR,
	NEXT_OF_KIN_REQUEST
} from "./nextOfKinTypes";

const initialState = {
	nextOfKin: [],
	nextOfKinsByAccountNumber: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case NEXT_OF_KIN_REQUEST:
			return {
				...state,
				loading: false,
			};
		case ADD_NEXT_OF_KIN:
			return {
				...state,
				nextOfKin: payload,
				loading: false,
			};
		case GET_NEXT_OF_KINS_BY_ACCOUNT_NUMBER:
			return {
				...state,
				nextOfKinsByAccountNumber: payload,
				loading: false,
			};
		case NEXT_OF_KIN_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}
