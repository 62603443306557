import { COUNTRY_REQUEST, ADD_COUNTRY, UPDATE_COUNTRY, COUNTRY_ERROR, GET_ALL_COUNTRIES } from "./countryTypes";

const initialState = {
	country: [],
	allCountries: [],
	loading: false,
	error: null,
};

export default function(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case COUNTRY_REQUEST:
			return { ...state, loading: true };
		case ADD_COUNTRY:
		case UPDATE_COUNTRY:
			return { ...state, country: payload };
		case GET_ALL_COUNTRIES:
			return { ...state, allCountries: payload };
		case COUNTRY_ERROR:
			return { ...state, error: payload };
      default:
        return state
	}
}
