import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "react-loader-spinner";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";

import "./App.css";
import { routes } from "./routes";
import { PrivateRoute } from "./routes/privateRoutes";
import { logout } from "./pages/Registration/UserManagement/Login/loginAction";

const Login = lazy(() => import("./pages/Registration/UserManagement/Login/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));

function App() {
	const { LOGIN, DASHBOARD } = routes;
	const { token } = useSelector((state) => state.login);
	const dispatch = useDispatch();

	useEffect(() => {
		if (token) {
			let decodedToken = jwt_decode(localStorage.getItem("cemastoken")).exp * 1000;

			let timeElapse = decodedToken - Date.now();
			let timeLeft = timeElapse - 5000;

			setTimeout(() => {
				let tokenExpiryCheck = setInterval(() => {

					if (decodedToken < Date.now()) {
						clearInterval(tokenExpiryCheck);
						dispatch(logout());
					}
				}, 1000);
			}, timeLeft);
		}
	}, [token]);

	return (
		<Router>
			<Suspense
				fallback={
					<div className="loader-wrapper">
						<Loader
							className="loader"
							type="Puff"
							color="#1b86df"
							height={100}
							width={100}
						/>
					</div>
				}
			>
				<div className="container-scroller">
					<Switch>
						<Route path={LOGIN} exact component={Login} />
						<PrivateRoute path={DASHBOARD} component={Dashboard} />
						<Route path="*" exact component={PageNotFound} />
					</Switch>
				</div>
			</Suspense>
		</Router>
	);
}

export default App;
