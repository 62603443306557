import{
    LOAN_BUDGET_REQUEST,
    CREATE_LOAN_BUDGET,
    LOAN_BUDGET_ERROR,
    UPDATE_LOAN_BUDGET,
    GET_ALL_LOAN_BUDGET
} from "./loanBudgetTypes";

const initialState = {
	getLoanBudgets: [],
	loanBudget: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case LOAN_BUDGET_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_LOAN_BUDGET:
		case UPDATE_LOAN_BUDGET:
			return {
				...state,
				loanBudget: payload,
				loading: false,
			};
		case GET_ALL_LOAN_BUDGET:
			return {
				...state,
				getLoanBudgets: payload,
				loading: false,
			};
		case LOAN_BUDGET_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
}