import { CLOSE, OPEN } from "./collapseTypes";

export const collapseOpened = (id) => async (dispatch) => {
  dispatch({
    type: OPEN,
    payload: id,
  });
};

export const openCollapse = (id) => async (dispatch) => {
  dispatch(collapseOpened(id));
};

export const closeCollapse = () => async (dispatch) => {
  dispatch({
    type: CLOSE,
  });
};
