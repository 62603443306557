export const LOAN_REGISTER_REQUEST = "LOAN_REGISTER_REQUEST";

export const CREATE_LOAN_REGISTER = "CREATE_LOAN_REGISTER";
export const GET_LOAN_REGISTER = "GET_LOAN_REGISTER";
export const GET_LOAN_REGISTER_BY_LOANCODE = "GET_LOAN_REGISTER_BY_LOANCODE";
export const GET_REGISTER_BY_ACCOUNTNUMBER = "GET_REGISTER_BY_ACCOUNTNUMBER";
export const GET_LOAN_BY_LOAN_CODE = "GET_LOAN_BY_LOAN_CODE";

export const UPDATE_LOAN_REGISTER = "UPDATE_LOAN_REGISTER";
export const UPDATE_LOAN_REGISTER_STATUS = "UPDATE_LOAN_REGISTER_STATUS";

export const LOAN_REGISTERED_APPROVAL = "LOAN_REGISTERED_APPROVAL";
export const GET_LOAN_STAGE = "GET_LOAN_STAGE";
export const DISBURSE_LOAN = "DISBURSE_LOAN";

export const APPRAISE_LOAN = "APPRAISE_LOAN";
export const GET_LOAN_REGISTERED_APPRAISAL = "GET_LOAN_REGISTERED_APPRAISAL";
export const GET_INCOME_CALCULATION_APPRAISAL = "GET_INCOME_CALCULATION_APPRAISAL";
export const GET_LOAN_OFFICER_CALCULATION = "GET_LOAN_OFFICER_CALCULATION";

export const APPROVE_LOAN = "APPROVE_LOAN";
export const GET_LOAN_APPROVAL_BY_LOAN_CODE = "GET_LOAN_APPROVAL_BY_LOAN_CODE";

export const GET_DISBURSEMENT_BY_LOAN_CODE = "GET_DISBURSEMENT_BY_LOAN_CODE";

export const LOAN_REGISTER_ERROR = "LOAN_REGISTER_ERROR";
export const LOAN_CAPITALIZATION = "LOAN_CAPITALIZATION";

export const CREATE_UPDATE_LOAN_PURPOSE="CREATE_UPDATE_LOAN_PURPOSE"
export const GET_ALL_LOAN_PURPOSE="GET_ALL_LOAN_PURPOSE"
export const VALIDATE_SECURITY_TYPE = "VALIDATE_SECURITY_TYPE"
export const CLEAR_LOAN_REGISTERS = "CLEAR_LOAN_REGISTER"