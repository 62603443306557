import {
  PAYBILL_RECORDS_REQUEST,
  GET_PAYBILL_RECORDS,
  PAYBILL_RECORDS_ERROR,
  GET_PAYBILL_RECORDS_BY_STATUS,
  EDIT_PAYBILL_RECORD
} from "./paybillTypes";

const initialState = {
  paybillRecords: null,
  loading: false,
  error: null
}

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case 
      PAYBILL_RECORDS_REQUEST,
      EDIT_PAYBILL_RECORD:
      return {
        ...state,
        loading: true
      }
    // case EDIT_PAYBILL_RECORD:
    //   return {
    //     ...state,
    //     // paybillRecords: payload,
    //     loading: false
    //   }
    case GET_PAYBILL_RECORDS:
      return {
        ...state,
        paybillRecords: payload,
        loading: false
      }
    case PAYBILL_RECORDS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      }
    case GET_PAYBILL_RECORDS_BY_STATUS:
      return {
        ...state,
        paybillRecords: payload,
        loading: false
      }
    default:
      return state;
  }
}