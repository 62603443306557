import {
  CREATE_WITHDRAWAL_CHARGE,
  WITHDRAWAL_CHARGE_ERROR,
  WITHDRAWAL_CHARGE_REQUEST,
  GET_ALL_WITHDRAWAL_CHARGES
} from './withdrawalChargeTypes';

const initialState = {
  withdrawalCharges: [],
  loading: false,
  error: null
}

export default function(state = initialState, action) {
  const { type, withdrawalCharges, error } = action;

  switch(type) {
    case WITHDRAWAL_CHARGE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_ALL_WITHDRAWAL_CHARGES:
      return {
        ...state,
        withdrawalCharges,
        loading: false
      }
    case WITHDRAWAL_CHARGE_ERROR:
      return {
        ...state,
        error,
        loading: false
      }
    default:
      return state
  }
}