/* eslint-disable import/no-anonymous-default-export */
import {
	ACCOUNT_DESIGNATION_ERROR,
	ACCOUNT_DESIGNATION_REQUEST,
	CREATE_ACCOUNT_DESIGNATION,
	GET_ALL_ACCOUNT_DESIGNATION,
	UPDATE_ACCOUNT_DESIGNATION
} from "./accountDesignationTypes";


const initialState = {
	designation: "",
	allAccountDesignation: [],
	loading: false,
	error: null,
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case ACCOUNT_DESIGNATION_REQUEST:
			return { ...state, loading: true };
		case CREATE_ACCOUNT_DESIGNATION:
		case UPDATE_ACCOUNT_DESIGNATION:
			return {
				...state,
				designation: payload,
				loading: false,
			};

		case GET_ALL_ACCOUNT_DESIGNATION:
			return {
				...state,
				allAccountDesignation: payload,
				loading: false,
			};
		case ACCOUNT_DESIGNATION_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			};
		default:
			return state;
	}
}
