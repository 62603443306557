import {
  DEPOSIT_PRODUCT_ERROR,
  DEPOSIT_PRODUCT_REQUEST,
  UPDATE_DEPOSIT_PRODUCT,
  CREATE_DEPOSIT_PRODUCT,
  GET_ALL_DEPOSIT_PRODUCTS,
  GET_DEPOSIT_PRODUCT_BY_ID,
  GET_DEPOSIT_PRODUCT_FOR_LOAN,
  CREATE_WITHDRAWAL,
  CREATE_DEPOSIT_CHARGES,
  GET_CUSTOMER_BALANCE_LIST,
  GET_ALL_DEPOSIT_CHARGES,
  CLEAR_DEPOSIT_PRODUCTS
} from "./depositProductTypes";

const initialState = {
  depositProducts: [],
  depositProduct: [],
  depositProductDetails: null,
  depositProductForLoan: [],
  withdrawal: {},
  depositCharges: {},
  customerBalanceListReport: [],
  loading: false,
  error: null,
  allDepositCharges: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DEPOSIT_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_DEPOSIT_PRODUCT:
    case UPDATE_DEPOSIT_PRODUCT:
      return {
        ...state,
        depositProduct: payload,
        loading: false,
      };
    case GET_ALL_DEPOSIT_PRODUCTS:
      return {
        ...state,
        depositProducts: payload,
        loading: false,
      };
    case GET_ALL_DEPOSIT_CHARGES:
      return {
        ...state,
        allDepositCharges: payload,
        loading: false,
      };
    case GET_DEPOSIT_PRODUCT_BY_ID:
      return {
        ...state,
        depositProductDetails: payload,
        loading: false,
      };
    case GET_DEPOSIT_PRODUCT_FOR_LOAN:
      return {
        ...state,
        depositProductForLoan: payload,
        loading: false,
      };
    case CREATE_WITHDRAWAL:
      return {
        ...state,
        withdrawal: payload,
        loading: false,
      };
    case CREATE_DEPOSIT_CHARGES:
      return {
        ...state,
        depositCharges: payload,
        loading: false,
      };
    case GET_CUSTOMER_BALANCE_LIST:
      return { ...state, customerBalanceListReport: payload, loading: false };
    case DEPOSIT_PRODUCT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_DEPOSIT_PRODUCTS:
      return initialState
    default:
      return state;
  }
}
